const _Environments = {
  production: {
    DOMAIN_NAME: 'server.urpc.us',
    API_BASE_URL: 'https://server.urpc.us/api/v1/',
    MQTT_URL: 'wss://server.urpc.us',
    MQTT_PORT: 1885,
    WEBSOCKET_PORT: 3002,
    STRIPE_API_KEY: 'pk_live_AH4a18MuFZKrjhP33tTG1RRM005tfEKPO8'
  },
  development: {
    DOMAIN_NAME: 'server-stage.urpc.us',
    API_BASE_URL: 'https://server-stage.urpc.us/api/v1/',
    MQTT_URL: 'wss://server-stage.urpc.us',
    MQTT_PORT: 1885,
    WEBSOCKET_PORT: 3002,
    STRIPE_API_KEY: 'pk_test_WHDzKuIPeWfJxWnM2OJgEopz00Rre4lR5t'
  },
  local: {
    DOMAIN_NAME: 'server-stage.urpc.us',
    API_BASE_URL: 'http://localhost:8000/api/v1/',
    MQTT_URL: 'wss://server-stage.urpc.us',
    MQTT_PORT: 1885,
    WEBSOCKET_PORT: 3002,
    STRIPE_API_KEY: 'pk_test_WHDzKuIPeWfJxWnM2OJgEopz00Rre4lR5t'
  },
};

export const baseUrl = (() => {
  return _Environments.development.API_BASE_URL;
})();

export const mqttUrl = (() => {
  return `${_Environments.development.MQTT_URL}:${_Environments.development.WEBSOCKET_PORT}`;
})();
