import {
  GET_RATE_CHART_SUCCESS,
  UPDATE_FIXED_RATE_CHART_SUCCESS,
  FIXED_RATE_CHART_UPDATED_DONE,
  UPDATE_SAPPHIRE_DURATION_RATE_CHART_SUCCESS,
  FIXED_SAPPHIRE_RATE_CHART_UPDATED_DONE,
  DURATION_RATE_CHART_UPDATED_DONE,
  UPDATE_DURATION_RATE_CHART_SUCCESS,
  DURATION_SAPPHIRE_RATE_CHART_UPDATED_DONE,
  RESET_RATE_CHART_DETAILS,
  RESET_SAPPHIRE_RATE_CHART_DETAILS,
  UPDATE_SAPPHIRE_FIXED_RATE_CHART_SUCCESS
} from "../constants/action-types";

const initialState = {
  rateChartDetails: {},
  fixedRateChartUpdated: false,
  durationRateChartUpdated: false
};

const rateChartData = (state = initialState, action) => {
  switch (action.type) {
    case GET_RATE_CHART_SUCCESS:
      return {
        ...state,
        rateChartDetails: action.payload.data
      };
    case UPDATE_FIXED_RATE_CHART_SUCCESS:
      let localRateChartDetails = { ...state.rateChartDetails.emerald };
      let localFixedData = { ...localRateChartDetails.fixed, ...action.payload.data };
      localRateChartDetails.fixed = localFixedData;
      return {
        ...state,
        fixedRateChartUpdated: true,
        rateChartDetails: {
          ...state.rateChartDetails,
          emerald: localRateChartDetails
        }
      };
    case UPDATE_SAPPHIRE_FIXED_RATE_CHART_SUCCESS:
      let localRateChartDetails1 = { ...state.rateChartDetails.sapphire };
      let localFixedData1 = { ...localRateChartDetails1.fixed, ...action.payload.data };
      localRateChartDetails1.fixed = localFixedData1;
      return {
        ...state,
        fixedRateChartUpdated: true,
        rateChartDetails: {
          ...state.rateChartDetails,
          sapphire: localRateChartDetails1
        }
      };
    case FIXED_RATE_CHART_UPDATED_DONE:
      return {
        ...state,
        fixedRateChartUpdated: false
      };
    case FIXED_SAPPHIRE_RATE_CHART_UPDATED_DONE:
      return {
        ...state,
        fixedRateChartUpdated: false
      };
    case UPDATE_DURATION_RATE_CHART_SUCCESS:
      let localRateChartData = { ...state.rateChartDetails.emerald };
      let localDurationData = { ...localRateChartData.duration, ...action.payload.data };
      localRateChartData.duration = localDurationData;
      return {
        ...state,
        durationRateChartUpdated: true,
        rateChartDetails: {
          ...state.rateChartDetails,
          emerald: localRateChartData
        }
      };
    case UPDATE_SAPPHIRE_DURATION_RATE_CHART_SUCCESS:
      let localRateChartData1 = { ...state.rateChartDetails.sapphire };
      let localDurationData1 = { ...localRateChartData1.duration, ...action.payload.data };
      localRateChartData1.duration = localDurationData1;
      return {
        ...state,
        durationRateChartUpdated: true,
        rateChartDetails: {
          ...state.rateChartDetails,
          sapphire: localRateChartData1
        }
      };
    case DURATION_RATE_CHART_UPDATED_DONE:
      return {
        ...state,
        durationRateChartUpdated: false
      };
    case DURATION_SAPPHIRE_RATE_CHART_UPDATED_DONE:
      return {
        ...state,
        durationRateChartUpdated: false
      };
    case RESET_RATE_CHART_DETAILS:
      return {
        ...state,
        rateChartDetails: {}
      };
    case RESET_SAPPHIRE_RATE_CHART_DETAILS:
      return {
        ...state,
        rateChartDetails: {}
      };
    default:
      return state;
  }
};

export default rateChartData;
