import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Divider from '@material-ui/core/Divider';
import { connect } from "react-redux";
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import theme from "../../../app/theme";
import PropTypes from 'prop-types';
import contentStyles from "../../auth-layout/content.styles";
import classNames from "classnames";
import globalstyles from "../../../globalstyles";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import styles from "./styles";
import Button from "@material-ui/core/Button";
import {
    getRateChart,
    updateFixedRateChart,
    updateFixedRateChartForSapphire,
    fixedRateChartDataUpdatedDone,
    updateDurationRateChart,
    updateDurationRateChartForSapphire,
    durationRateChartDataUpdatedDone,
    resetDetails,
    resetDetails1
} from "../../../store/actions/rateChart";

function TabContainer({ children }) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired
};

class RateCharts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            masterTabValue: 0,
            detailsFetched: false,
            editableMode: false,
            fixedRateChartDetails: {
                baseFarePerKmSedan: "",
                baseFarePerKmSUV: "",
                chargePerKmSedan: "",
                chargePerKmSUV: "",
                chargePerMinTravelTimeSedan: "",
                chargePerMinTravelTimeSUV: "",
                waitingTimeChargeSedan: "",
                waitingTimeChargeSUV: "",
                discountPercentage: ""
            },
            durationRateChartDetails: {
                baseFareSedan: "",
                baseFareSUV: "",
                chargePerMinWaitingTimeSedan: "",
                chargePerMinWaitingTimeSUV: "",
                hourlyPerHourBookingSedan: "",
                hourlyPerHourBookingSUV: "",
                hourlyPenaltyChargeSedan: "",
                hourlyPenaltyChargeSUV: "",
                hourlyExcessMin: "",
                hourlyExcessChargeSedan: "",
                hourlyExcessChargeSUV: "",
                halfDayPerHourBookingSedan: "",
                halfDayPerHourBookingSUV: "",
                halfDayPenaltyChargeSedan: "",
                halfDayPenaltyChargeSUV: "",
                halfDayExcessMin: "",
                halfDayExcessChargeSedan: "",
                halfDayExcessChargeSUV: "",
                fullDayPerHourBookingSedan: "",
                fullDayPerHourBookingSUV: "",
                fullDayPenaltyChargeSedan: "",
                fullDayPenaltyChargeSUV: "",
                fullDayExcessMin: "",
                fullDayExcessChargeSedan: "",
                fullDayExcessChargeSUV: "",
                discountPercentageDuration: ""
            },
            errors: {},
            searchData: {},
            tabValue: 0,
            detailsFetched1: false,
            editableMode1: false,
            fixedRateChartDetails1: {
                baseFarePerKmSedan: "",
                baseFarePerKmSUV: "",
                chargePerKmSedan: "",
                chargePerKmSUV: "",
                chargePerMinTravelTimeSedan: "",
                chargePerMinTravelTimeSUV: "",
                waitingTimeChargeSedan: "",
                waitingTimeChargeSUV: "",
                discountPercentage: ""
            },
            durationRateChartDetails1: {
                baseFareSedan: "",
                baseFareSUV: "",
                chargePerMinWaitingTimeSedan: "",
                chargePerMinWaitingTimeSUV: "",
                hourlyPerHourBookingSedan: "",
                hourlyPerHourBookingSUV: "",
                hourlyPenaltyChargeSedan: "",
                hourlyPenaltyChargeSUV: "",
                hourlyExcessMin: "",
                hourlyExcessChargeSedan: "",
                hourlyExcessChargeSUV: "",
                halfDayPerHourBookingSedan: "",
                halfDayPerHourBookingSUV: "",
                halfDayPenaltyChargeSedan: "",
                halfDayPenaltyChargeSUV: "",
                halfDayExcessMin: "",
                halfDayExcessChargeSedan: "",
                halfDayExcessChargeSUV: "",
                fullDayPerHourBookingSedan: "",
                fullDayPerHourBookingSUV: "",
                fullDayPenaltyChargeSedan: "",
                fullDayPenaltyChargeSUV: "",
                fullDayExcessMin: "",
                fullDayExcessChargeSedan: "",
                fullDayExcessChargeSUV: "",
                discountPercentageDuration: ""
            },
            errors1: {},
            searchData1: {},
            tabValue1: 0,
        };
    }

    columnWidth = 100;
    minColumnLength = 5;


    componentDidMount() {
        let localStorageData = window.localStorage.upcAuthData;
        if (localStorageData && JSON.parse(localStorageData)) {
            if (!JSON.parse(localStorageData).user.roleInfo.isSuperAdmin) {
                JSON.parse(localStorageData).user.roleInfo.roleId.permissions.forEach(
                    each => {
                        if (each.moduleName === "fareChart") {
                            if (each.role === 2) {
                                this.setState({
                                    ...this.state,
                                    editableMode: true,
                                    editableMode1: true
                                });
                            }
                        }
                    }
                );
            } else {
                this.setState({
                    ...this.state,
                    editableMode: true,
                    editableMode1: true
                });
            }
        }
        this.props.getRateChart();
    }
    componentDidUpdate(prevProps) {
        const { rateChartData } = this.props.data;
    
        if (!rateChartData || !rateChartData.rateChartDetails) return;
    
        // Checking for changes in 'emerald' rate chart data
        if (rateChartData.rateChartDetails.emerald 
            && Object.keys(rateChartData.rateChartDetails.emerald).length 
            && !this.state.detailsFetched) {
                
            let localFixedRateChartData = { ...this.state.fixedRateChartDetails };
            let localDurationRateChartData = { ...this.state.durationRateChartDetails };
            localFixedRateChartData.baseFarePerKmSedan = rateChartData.rateChartDetails.emerald.fixed.baseFare.sedan;
            localFixedRateChartData.baseFarePerKmSUV = rateChartData.rateChartDetails.emerald.fixed.baseFare.suv;
            localFixedRateChartData.chargePerKmSedan = rateChartData.rateChartDetails.emerald.fixed.chargePerDistanceUnit.sedan;
            localFixedRateChartData.chargePerKmSUV = rateChartData.rateChartDetails.emerald.fixed.chargePerDistanceUnit.suv;
            localFixedRateChartData.chargePerMinTravelTimeSedan = rateChartData.rateChartDetails.emerald.fixed.chargePerMinute.sedan;
            localFixedRateChartData.chargePerMinTravelTimeSUV = rateChartData.rateChartDetails.emerald.fixed.chargePerMinute.suv;
            localFixedRateChartData.waitingTimeChargeSedan = rateChartData.rateChartDetails.emerald.fixed.waitingChargePerMinute.sedan;
            localFixedRateChartData.waitingTimeChargeSUV = rateChartData.rateChartDetails.emerald.fixed.waitingChargePerMinute.suv;
            localFixedRateChartData.discountPercentage = rateChartData.rateChartDetails.emerald.fixed.discountPercentage;

            for (let item in localFixedRateChartData) {
                localFixedRateChartData[item] = localFixedRateChartData[item].toString();
            }

            localDurationRateChartData.discountPercentageDuration = rateChartData.rateChartDetails.emerald.duration.discountPercentage;
            localDurationRateChartData.baseFareSedan = rateChartData.rateChartDetails.emerald.duration.baseFare.sedan;
            localDurationRateChartData.baseFareSUV = rateChartData.rateChartDetails.emerald.duration.baseFare.suv;
            localDurationRateChartData.chargePerMinWaitingTimeSedan = rateChartData.rateChartDetails.emerald.duration.waitingChargePerMinute.sedan;
            localDurationRateChartData.chargePerMinWaitingTimeSUV = rateChartData.rateChartDetails.emerald.duration.waitingChargePerMinute.suv;
            localDurationRateChartData.hourlyPerHourBookingSedan = rateChartData.rateChartDetails.emerald.duration.hourly.perHourCost.sedan;
            localDurationRateChartData.hourlyPerHourBookingSUV = rateChartData.rateChartDetails.emerald.duration.hourly.perHourCost.suv;
            localDurationRateChartData.hourlyPenaltyChargeSedan = rateChartData.rateChartDetails.emerald.duration.hourly.perHourPenaltyCost.sedan;
            localDurationRateChartData.hourlyPenaltyChargeSUV = rateChartData.rateChartDetails.emerald.duration.hourly.perHourPenaltyCost.suv;
            localDurationRateChartData.hourlyExcessMin = rateChartData.rateChartDetails.emerald.duration.hourly.additionalExcessCost.exceededTime;
            localDurationRateChartData.hourlyExcessChargeSedan = rateChartData.rateChartDetails.emerald.duration.hourly.additionalExcessCost.penaltyCost.sedan;
            localDurationRateChartData.hourlyExcessChargeSUV = rateChartData.rateChartDetails.emerald.duration.hourly.additionalExcessCost.penaltyCost.suv;
            localDurationRateChartData.halfDayPerHourBookingSedan = rateChartData.rateChartDetails.emerald.duration.halfDay.perHourCost.sedan;
            localDurationRateChartData.halfDayPerHourBookingSUV = rateChartData.rateChartDetails.emerald.duration.halfDay.perHourCost.suv;
            localDurationRateChartData.halfDayPenaltyChargeSedan = rateChartData.rateChartDetails.emerald.duration.halfDay.perHourPenaltyCost.sedan;
            localDurationRateChartData.halfDayPenaltyChargeSUV = rateChartData.rateChartDetails.emerald.duration.halfDay.perHourPenaltyCost.suv;
            localDurationRateChartData.halfDayExcessMin = rateChartData.rateChartDetails.emerald.duration.halfDay.additionalExcessCost.exceededTime;
            localDurationRateChartData.halfDayExcessChargeSedan = rateChartData.rateChartDetails.emerald.duration.halfDay.additionalExcessCost.penaltyCost.sedan;
            localDurationRateChartData.halfDayExcessChargeSUV = rateChartData.rateChartDetails.emerald.duration.halfDay.additionalExcessCost.penaltyCost.suv;
            localDurationRateChartData.fullDayPerHourBookingSedan = rateChartData.rateChartDetails.emerald.duration.fullDay.perHourCost.sedan;
            localDurationRateChartData.fullDayPerHourBookingSUV = rateChartData.rateChartDetails.emerald.duration.fullDay.perHourCost.suv;
            localDurationRateChartData.fullDayPenaltyChargeSedan = rateChartData.rateChartDetails.emerald.duration.fullDay.perHourPenaltyCost.sedan;
            localDurationRateChartData.fullDayPenaltyChargeSUV = rateChartData.rateChartDetails.emerald.duration.fullDay.perHourPenaltyCost.suv;
            localDurationRateChartData.fullDayExcessMin = rateChartData.rateChartDetails.emerald.duration.fullDay.additionalExcessCost.exceededTime;
            localDurationRateChartData.fullDayExcessChargeSedan = rateChartData.rateChartDetails.emerald.duration.fullDay.additionalExcessCost.penaltyCost.sedan;
            localDurationRateChartData.fullDayExcessChargeSUV = rateChartData.rateChartDetails.emerald.duration.fullDay.additionalExcessCost.penaltyCost.suv;

            for (let item in localDurationRateChartData) {
                localDurationRateChartData[item] = localDurationRateChartData[item].toString();
            }

            this.setState({ ...this.state, fixedRateChartDetails: localFixedRateChartData, durationRateChartDetails: localDurationRateChartData, detailsFetched: true });
        }

        // Repeat for 'sapphire' rate chart data
    if (rateChartData.rateChartDetails.sapphire 
        && Object.keys(rateChartData.rateChartDetails.sapphire).length 
        && !this.state.detailsFetched1) {
            console.log('rateChartData.rateChartDetails.sapphire', rateChartData.rateChartDetails.sapphire)
            let localFixedRateChartData = { ...this.state.fixedRateChartDetails1 };
            let localDurationRateChartData = { ...this.state.durationRateChartDetails1 };
            localFixedRateChartData.baseFarePerKmSedan = rateChartData.rateChartDetails.sapphire.fixed.baseFare.sedan;
            localFixedRateChartData.baseFarePerKmSUV = rateChartData.rateChartDetails.sapphire.fixed.baseFare.suv;
            localFixedRateChartData.chargePerKmSedan = rateChartData.rateChartDetails.sapphire.fixed.chargePerDistanceUnit.sedan;
            localFixedRateChartData.chargePerKmSUV = rateChartData.rateChartDetails.sapphire.fixed.chargePerDistanceUnit.suv;
            localFixedRateChartData.chargePerMinTravelTimeSedan = rateChartData.rateChartDetails.sapphire.fixed.chargePerMinute.sedan;
            localFixedRateChartData.chargePerMinTravelTimeSUV = rateChartData.rateChartDetails.sapphire.fixed.chargePerMinute.suv;
            localFixedRateChartData.waitingTimeChargeSedan = rateChartData.rateChartDetails.sapphire.fixed.waitingChargePerMinute.sedan;
            localFixedRateChartData.waitingTimeChargeSUV = rateChartData.rateChartDetails.sapphire.fixed.waitingChargePerMinute.suv;
            localFixedRateChartData.discountPercentage = rateChartData.rateChartDetails.sapphire.fixed.discountPercentage;

            for (let item in localFixedRateChartData) {
                localFixedRateChartData[item] = localFixedRateChartData[item].toString();
            }

            localDurationRateChartData.discountPercentageDuration = rateChartData.rateChartDetails.sapphire.duration.discountPercentage;
            localDurationRateChartData.baseFareSedan = rateChartData.rateChartDetails.sapphire.duration.baseFare.sedan;
            localDurationRateChartData.baseFareSUV = rateChartData.rateChartDetails.sapphire.duration.baseFare.suv;
            localDurationRateChartData.chargePerMinWaitingTimeSedan = rateChartData.rateChartDetails.sapphire.duration.waitingChargePerMinute.sedan;
            localDurationRateChartData.chargePerMinWaitingTimeSUV = rateChartData.rateChartDetails.sapphire.duration.waitingChargePerMinute.suv;
            localDurationRateChartData.hourlyPerHourBookingSedan = rateChartData.rateChartDetails.sapphire.duration.hourly.perHourCost.sedan;
            localDurationRateChartData.hourlyPerHourBookingSUV = rateChartData.rateChartDetails.sapphire.duration.hourly.perHourCost.suv;
            localDurationRateChartData.hourlyPenaltyChargeSedan = rateChartData.rateChartDetails.sapphire.duration.hourly.perHourPenaltyCost.sedan;
            localDurationRateChartData.hourlyPenaltyChargeSUV = rateChartData.rateChartDetails.sapphire.duration.hourly.perHourPenaltyCost.suv;
            localDurationRateChartData.hourlyExcessMin = rateChartData.rateChartDetails.sapphire.duration.hourly.additionalExcessCost.exceededTime;
            localDurationRateChartData.hourlyExcessChargeSedan = rateChartData.rateChartDetails.sapphire.duration.hourly.additionalExcessCost.penaltyCost.sedan;
            localDurationRateChartData.hourlyExcessChargeSUV = rateChartData.rateChartDetails.sapphire.duration.hourly.additionalExcessCost.penaltyCost.suv;
            localDurationRateChartData.halfDayPerHourBookingSedan = rateChartData.rateChartDetails.sapphire.duration.halfDay.perHourCost.sedan;
            localDurationRateChartData.halfDayPerHourBookingSUV = rateChartData.rateChartDetails.sapphire.duration.halfDay.perHourCost.suv;
            localDurationRateChartData.halfDayPenaltyChargeSedan = rateChartData.rateChartDetails.sapphire.duration.halfDay.perHourPenaltyCost.sedan;
            localDurationRateChartData.halfDayPenaltyChargeSUV = rateChartData.rateChartDetails.sapphire.duration.halfDay.perHourPenaltyCost.suv;
            localDurationRateChartData.halfDayExcessMin = rateChartData.rateChartDetails.sapphire.duration.halfDay.additionalExcessCost.exceededTime;
            localDurationRateChartData.halfDayExcessChargeSedan = rateChartData.rateChartDetails.sapphire.duration.halfDay.additionalExcessCost.penaltyCost.sedan;
            localDurationRateChartData.halfDayExcessChargeSUV = rateChartData.rateChartDetails.sapphire.duration.halfDay.additionalExcessCost.penaltyCost.suv;
            localDurationRateChartData.fullDayPerHourBookingSedan = rateChartData.rateChartDetails.sapphire.duration.fullDay.perHourCost.sedan;
            localDurationRateChartData.fullDayPerHourBookingSUV = rateChartData.rateChartDetails.sapphire.duration.fullDay.perHourCost.suv;
            localDurationRateChartData.fullDayPenaltyChargeSedan = rateChartData.rateChartDetails.sapphire.duration.fullDay.perHourPenaltyCost.sedan;
            localDurationRateChartData.fullDayPenaltyChargeSUV = rateChartData.rateChartDetails.sapphire.duration.fullDay.perHourPenaltyCost.suv;
            localDurationRateChartData.fullDayExcessMin = rateChartData.rateChartDetails.sapphire.duration.fullDay.additionalExcessCost.exceededTime;
            localDurationRateChartData.fullDayExcessChargeSedan = rateChartData.rateChartDetails.sapphire.duration.fullDay.additionalExcessCost.penaltyCost.sedan;
            localDurationRateChartData.fullDayExcessChargeSUV = rateChartData.rateChartDetails.sapphire.duration.fullDay.additionalExcessCost.penaltyCost.suv;

            for (let item in localDurationRateChartData) {
                localDurationRateChartData[item] = localDurationRateChartData[item].toString();
            }

            this.setState({ ...this.state, fixedRateChartDetails1: localFixedRateChartData, durationRateChartDetails1: localDurationRateChartData, detailsFetched1: true });
        }

            // Handling updates in rate chart data
        if (rateChartData.rateChartUpdated1 && prevProps.data.rateChartData.rateChartUpdated1 !== rateChartData.rateChartUpdated1) {
            this.props.sapphireFixedRateChartDataUpdatedDone();
        }

        if (rateChartData.durationRateChartUpdated1 && prevProps.data.rateChartData.durationRateChartUpdated1 !== rateChartData.durationRateChartUpdated1) {
            this.props.sapphiredurationRateChartDataUpdatedDone();
        }

    }

    componentWillUnmount() {
        this.props.resetDetails();
        this.props.resetDetails1();
    }

    handleTabChange = (event, tabValue) => {
        let errors = { ...this.state.errors };
        if (Object.keys(errors).length) {
            for (let item in errors) {
                errors[item] = false;
            }
        }
        this.setState({ ...this.state, tabValue, errors, detailsFetched: false });
    };

    handleTabChange1 = (event, tabValue1) => {
        let errors1 = { ...this.state.errors1 };
        if (Object.keys(errors1).length) {
            for (let item in errors1) {
                errors1[item] = false;
            }
        }
        this.setState({ ...this.state, tabValue1, errors1, detailsFetched1: false });
    };

    handleDurationRateChange = (event) => {
        let fields = { ...this.state.durationRateChartDetails };
        let errors = { ...this.state.errors };
        fields[event.target.name] = event.target.value;
        errors[event.target.name] = false;

        this.setState({
            ...this.state,
            durationRateChartDetails: fields,
            errors
        });
    };

    handleDurationRateChange1 = (event) => {
        let fields = { ...this.state.durationRateChartDetails1 };
        let errors1 = { ...this.state.errors1 };
        fields[event.target.name] = event.target.value;
        errors1[event.target.name] = false;

        this.setState({
            ...this.state,
            durationRateChartDetails1: fields,
            errors1
        });
    };

    handleChange = (event) => {
        let fields = { ...this.state.fixedRateChartDetails };
        let errors = { ...this.state.errors };
        errors[event.target.name] = false;
        fields[event.target.name] = event.target.value;


        this.setState({
            ...this.state,
            fixedRateChartDetails: fields,
            errors
        });
    };
    handleChange1 = (event) => {
        let fields = { ...this.state.fixedRateChartDetails1 };
        let errors1 = { ...this.state.errors1 };
        errors1[event.target.name] = false;
        fields[event.target.name] = event.target.value;


        this.setState({
            ...this.state,
            fixedRateChartDetails1: fields,
            errors1
        });
    };
    handleMasterTabChange = (event) => {
        this.setState({
            ...this.state,
            masterTabValue: this.state.masterTabValue === 1 ? 0 : 1,
            tabValue: 0,
            tabValue1: 0,
        });
    }

    handleBlur = (event) => {
        let errors = { ...this.state.errors };
        if (
            /^(\$?\d{1,3}(?:,?\d{3})*(?:\.\d{2})?|\.\d{2})?$/.test(event.target.value)
        ) {
            errors[event.target.name] = false;
        } else {
            errors[event.target.name] = true;
        }
        if (event.target.name === "discountPercentageDuration"
            || event.target.name === "discountPercentage") {
            if (event.target.value > 100) {
                errors[event.target.name] = true;
            } else {
                errors[event.target.name] = false;
            }
        }
        this.setState({
            ...this.state,
            errors
        });
    };

    handleBlur1 = (event) => {
        let errors1 = { ...this.state.errors1 };
        if (
            /^(\$?\d{1,3}(?:,?\d{3})*(?:\.\d{2})?|\.\d{2})?$/.test(event.target.value)
        ) {
            errors1[event.target.name] = false;
        } else {
            errors1[event.target.name] = true;
        }
        if (event.target.name === "discountPercentageDuration"
            || event.target.name === "discountPercentage") {
            if (event.target.value > 100) {
                errors1[event.target.name] = true;
            } else {
                errors1[event.target.name] = false;
            }
        }
        this.setState({
            ...this.state,
            errors1
        });
    };

    rateChartInfoValidation = () => {
        let fields = {};
        if (this.state.tabValue === 0) {
            fields = { ...this.state.fixedRateChartDetails };
        } else {
            fields = { ...this.state.durationRateChartDetails };
        }
        let errors = {
            ...this.state.errors
        };
        let hasErrors = false;
        for (let name in fields) {
            if (fields[name].trim() === "" || errors[name]) {
                errors[name] = true;
                hasErrors = true;
            }
        }
        console.log('errors ', errors, hasErrors);


        this.setState({ errors: errors });
        return hasErrors;
    };

    rateChartInfoValidation1 = () => {
        let fields = {};
        if (this.state.tabValue1 === 0) {
            fields = { ...this.state.fixedRateChartDetails1 };
        } else {
            fields = { ...this.state.durationRateChartDetails1 };
        }
        let errors1 = {
            ...this.state.errors1
        };
        let hasErrors = false;
        for (let name in fields) {
            if (fields[name].trim() === "" || errors1[name]) {
                errors1[name] = true;
                hasErrors = true;
            }
        }
        console.log('errors ', errors1, hasErrors);


        this.setState({ errors1: errors1 });
        return hasErrors;
    };

    saveHandler = () => {
        if (!this.rateChartInfoValidation()) {
            console.log('in save');
            let requestData = {};
            if (this.state.tabValue === 0) {
                let localFixedRateChartData = { ...this.state.fixedRateChartDetails };
                requestData = {
                    "discountPercentage": Number(localFixedRateChartData.discountPercentage),
                    "waitingChargePerMinute": {
                        "sedan": Number(localFixedRateChartData.waitingTimeChargeSedan),
                        "suv": Number(localFixedRateChartData.waitingTimeChargeSUV)
                    },
                    "chargePerMinute": {
                        "sedan": Number(localFixedRateChartData.chargePerMinTravelTimeSedan),
                        "suv": Number(localFixedRateChartData.chargePerMinTravelTimeSUV)
                    },
                    "chargePerDistanceUnit": {
                        "sedan": Number(localFixedRateChartData.chargePerKmSedan),
                        "suv": Number(localFixedRateChartData.chargePerKmSUV)
                    },
                    "baseFare": {
                        "sedan": Number(localFixedRateChartData.baseFarePerKmSedan),
                        "suv": Number(localFixedRateChartData.baseFarePerKmSUV)
                    }
                }
                console.log('requestData ', requestData);
                this.props.updateFixedRateChart(requestData);

            } else {
                let localDurationRateChartData = { ...this.state.durationRateChartDetails };
                requestData = {
                    "discountPercentage": Number(localDurationRateChartData.discountPercentageDuration),
                    "fullDay": {
                        "additionalExcessCost": {
                            "exceededTime": Number(localDurationRateChartData.fullDayExcessMin),
                            "penaltyCost": {
                                "sedan": Number(localDurationRateChartData.fullDayExcessChargeSedan),
                                "suv": Number(localDurationRateChartData.fullDayExcessChargeSUV)
                            }
                        },
                        "perHourPenaltyCost": {
                            "sedan": Number(localDurationRateChartData.fullDayPenaltyChargeSedan),
                            "suv": Number(localDurationRateChartData.fullDayPenaltyChargeSUV)
                        },
                        "perHourCost": {
                            "sedan": Number(localDurationRateChartData.fullDayPerHourBookingSedan),
                            "suv": Number(localDurationRateChartData.fullDayPerHourBookingSUV)
                        }
                    },
                    "halfDay": {
                        "additionalExcessCost": {
                            "exceededTime": Number(localDurationRateChartData.halfDayExcessMin),
                            "penaltyCost": {
                                "sedan": Number(localDurationRateChartData.halfDayExcessChargeSedan),
                                "suv": Number(localDurationRateChartData.halfDayExcessChargeSUV)
                            }
                        },
                        "perHourPenaltyCost": {
                            "sedan": Number(localDurationRateChartData.halfDayPenaltyChargeSedan),
                            "suv": Number(localDurationRateChartData.halfDayPenaltyChargeSUV)
                        },
                        "perHourCost": {
                            "sedan": Number(localDurationRateChartData.halfDayPerHourBookingSedan),
                            "suv": Number(localDurationRateChartData.halfDayPerHourBookingSUV)
                        }
                    },
                    "hourly": {
                        "additionalExcessCost": {
                            "exceededTime": Number(localDurationRateChartData.hourlyExcessMin),
                            "penaltyCost": {
                                "sedan": Number(localDurationRateChartData.hourlyExcessChargeSedan),
                                "suv": Number(localDurationRateChartData.hourlyExcessChargeSUV)
                            }
                        },
                        "perHourPenaltyCost": {
                            "sedan": Number(localDurationRateChartData.hourlyPenaltyChargeSedan),
                            "suv": Number(localDurationRateChartData.hourlyPenaltyChargeSUV)
                        },
                        "perHourCost": {
                            "sedan": Number(localDurationRateChartData.hourlyPerHourBookingSedan),
                            "suv": Number(localDurationRateChartData.hourlyPerHourBookingSUV)
                        }
                    },
                    "waitingChargePerMinute": {
                        "sedan": Number(localDurationRateChartData.chargePerMinWaitingTimeSedan),
                        "suv": Number(localDurationRateChartData.chargePerMinWaitingTimeSUV)
                    },
                    "baseFare": {
                        "sedan": Number(localDurationRateChartData.baseFareSedan),
                        "suv": Number(localDurationRateChartData.baseFareSUV)
                    }
                }
                console.log('requestData ', requestData);
                this.props.updateDurationRateChart(requestData);
            }
        }
    };

    saveHandler1 = () => {
        if (!this.rateChartInfoValidation1()) {
            console.log('in save');
            let requestData = {};
            if (this.state.tabValue1 === 0) {
                let localFixedRateChartData = { ...this.state.fixedRateChartDetails1 };
                requestData = {
                    "discountPercentage": Number(localFixedRateChartData.discountPercentage),
                    "waitingChargePerMinute": {
                        "sedan": Number(localFixedRateChartData.waitingTimeChargeSedan),
                        "suv": Number(localFixedRateChartData.waitingTimeChargeSUV)
                    },
                    "chargePerMinute": {
                        "sedan": Number(localFixedRateChartData.chargePerMinTravelTimeSedan),
                        "suv": Number(localFixedRateChartData.chargePerMinTravelTimeSUV)
                    },
                    "chargePerDistanceUnit": {
                        "sedan": Number(localFixedRateChartData.chargePerKmSedan),
                        "suv": Number(localFixedRateChartData.chargePerKmSUV)
                    },
                    "baseFare": {
                        "sedan": Number(localFixedRateChartData.baseFarePerKmSedan),
                        "suv": Number(localFixedRateChartData.baseFarePerKmSUV)
                    }
                }
                console.log('requestData ', requestData);
                this.props.updateFixedRateChartForSapphire(requestData);

            } else {
                let localDurationRateChartData = { ...this.state.durationRateChartDetails1 };
                requestData = {
                    "discountPercentage": Number(localDurationRateChartData.discountPercentageDuration),
                    "fullDay": {
                        "additionalExcessCost": {
                            "exceededTime": Number(localDurationRateChartData.fullDayExcessMin),
                            "penaltyCost": {
                                "sedan": Number(localDurationRateChartData.fullDayExcessChargeSedan),
                                "suv": Number(localDurationRateChartData.fullDayExcessChargeSUV)
                            }
                        },
                        "perHourPenaltyCost": {
                            "sedan": Number(localDurationRateChartData.fullDayPenaltyChargeSedan),
                            "suv": Number(localDurationRateChartData.fullDayPenaltyChargeSUV)
                        },
                        "perHourCost": {
                            "sedan": Number(localDurationRateChartData.fullDayPerHourBookingSedan),
                            "suv": Number(localDurationRateChartData.fullDayPerHourBookingSUV)
                        }
                    },
                    "halfDay": {
                        "additionalExcessCost": {
                            "exceededTime": Number(localDurationRateChartData.halfDayExcessMin),
                            "penaltyCost": {
                                "sedan": Number(localDurationRateChartData.halfDayExcessChargeSedan),
                                "suv": Number(localDurationRateChartData.halfDayExcessChargeSUV)
                            }
                        },
                        "perHourPenaltyCost": {
                            "sedan": Number(localDurationRateChartData.halfDayPenaltyChargeSedan),
                            "suv": Number(localDurationRateChartData.halfDayPenaltyChargeSUV)
                        },
                        "perHourCost": {
                            "sedan": Number(localDurationRateChartData.halfDayPerHourBookingSedan),
                            "suv": Number(localDurationRateChartData.halfDayPerHourBookingSUV)
                        }
                    },
                    "hourly": {
                        "additionalExcessCost": {
                            "exceededTime": Number(localDurationRateChartData.hourlyExcessMin),
                            "penaltyCost": {
                                "sedan": Number(localDurationRateChartData.hourlyExcessChargeSedan),
                                "suv": Number(localDurationRateChartData.hourlyExcessChargeSUV)
                            }
                        },
                        "perHourPenaltyCost": {
                            "sedan": Number(localDurationRateChartData.hourlyPenaltyChargeSedan),
                            "suv": Number(localDurationRateChartData.hourlyPenaltyChargeSUV)
                        },
                        "perHourCost": {
                            "sedan": Number(localDurationRateChartData.hourlyPerHourBookingSedan),
                            "suv": Number(localDurationRateChartData.hourlyPerHourBookingSUV)
                        }
                    },
                    "waitingChargePerMinute": {
                        "sedan": Number(localDurationRateChartData.chargePerMinWaitingTimeSedan),
                        "suv": Number(localDurationRateChartData.chargePerMinWaitingTimeSUV)
                    },
                    "baseFare": {
                        "sedan": Number(localDurationRateChartData.baseFareSedan),
                        "suv": Number(localDurationRateChartData.baseFareSUV)
                    }
                }
                console.log('requestData ', requestData);
                this.props.updateDurationRateChartForSapphire(requestData);
            }
        }
    };

    resetForm = () => {
        this.setState({ ...this.state, detailsFetched: false, detailsFetched1: false });
    }

    render() {
        const { classes } = this.props;
        const { tabValue } = this.state;
        const { tabValue1 } = this.state;
        const { masterTabValue } = this.state;
        return (
            <MuiThemeProvider theme={theme}>
                <div>
                    <Grid container spacing={24}>
                        <Grid item xs={12} sm={12}>
                            <Grid container>
                                <Grid item xs={12} sm={10} style={{ paddingTop: "10px" }}>
                                    <Typography component="p">
                                        <span className={classes.pageHeader} >
                                            {this.props.data.literalsReducer.RateCharts.PageHeader}
                                        </span>
                                        <span className={classes.PageDescription}>
                                            {
                                                this.props.data.literalsReducer.RateCharts
                                                    .PageDescription
                                            }
                                        </span>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>


                        <Grid item xs={12} sm={12}>
                            <Paper>
                                <Grid container className={classes.rightPaper}>
                                    <Grid item xs={12} sm={12}>
                                        <Tabs
                                            value={masterTabValue}
                                            onChange={this.handleMasterTabChange}
                                            className={classes.tabPan}
                                            variant="fullWidth"
                                        >
                                            <Tab label={this.props.data.literalsReducer.RateCharts.Emerald} />
                                            <Tab label={this.props.data.literalsReducer.RateCharts.Sapphire} />
                                        </Tabs>

                                        {masterTabValue === 0 && <Grid item xs={12} sm={12}>
                                            <Paper>
                                                <Grid container className={classes.rightPaper}>
                                                    <Grid item xs={12} sm={12}>
                                                        <Tabs
                                                            value={tabValue}
                                                            onChange={this.handleTabChange}
                                                            className={classes.tabPan}
                                                            variant="fullWidth"
                                                        >
                                                            <Tab label={this.props.data.literalsReducer.RateCharts.FixedRides} />
                                                            <Tab label={this.props.data.literalsReducer.RateCharts.DurationBasedRides} />
                                                        </Tabs>
                                                        {tabValue === 0 && <TabContainer>
                                                            <div className={classes.description}>
                                                                {this.props.data.literalsReducer.RateCharts.BaseFare} -
                                                                <span>Sedan</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}

                                                                    name="baseFarePerKmSedan"
                                                                    value={
                                                                        this.state.fixedRateChartDetails
                                                                            .baseFarePerKmSedan
                                                                    }
                                                                    onChange={this.handleChange}
                                                                    onBlur={this.handleBlur}
                                                                    error={this.state.errors.baseFarePerKmSedan}
                                                                    disabled={!this.state.editableMode}
                                                                />
                                                                <span>SUV</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur}
                                                                    name="baseFarePerKmSUV"
                                                                    value={
                                                                        this.state.fixedRateChartDetails
                                                                            .baseFarePerKmSUV
                                                                    }
                                                                    onChange={this.handleChange}
                                                                    error={this.state.errors.baseFarePerKmSUV}
                                                                    disabled={!this.state.editableMode}
                                                                />
                                                            </div>
                                                            <div className={classes.description}>
                                                                {this.props.data.literalsReducer.RateCharts.ChargePerKm} -
                                                                <span>Sedan</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur}
                                                                    name="chargePerKmSedan"
                                                                    value={
                                                                        this.state.fixedRateChartDetails
                                                                            .chargePerKmSedan
                                                                    }
                                                                    onChange={this.handleChange}
                                                                    error={this.state.errors.chargePerKmSedan}
                                                                    disabled={!this.state.editableMode}
                                                                />
                                                                <span>SUV</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur}
                                                                    name="chargePerKmSUV"
                                                                    value={
                                                                        this.state.fixedRateChartDetails
                                                                            .chargePerKmSUV
                                                                    }
                                                                    onChange={this.handleChange}
                                                                    error={this.state.errors.chargePerKmSUV}
                                                                    disabled={!this.state.editableMode}
                                                                />
                                                            </div>
                                                            <div className={classes.description}>
                                                                {this.props.data.literalsReducer.RateCharts.ChargePerMinTravelTime} -
                                                                <span>Sedan</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur}
                                                                    name="chargePerMinTravelTimeSedan"
                                                                    value={
                                                                        this.state.fixedRateChartDetails
                                                                            .chargePerMinTravelTimeSedan
                                                                    }
                                                                    onChange={this.handleChange}
                                                                    error={this.state.errors.chargePerMinTravelTimeSedan}
                                                                    disabled={!this.state.editableMode}
                                                                />
                                                                <span>SUV</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur}
                                                                    name="chargePerMinTravelTimeSUV"
                                                                    value={
                                                                        this.state.fixedRateChartDetails
                                                                            .chargePerMinTravelTimeSUV
                                                                    }
                                                                    onChange={this.handleChange}
                                                                    error={this.state.errors.chargePerMinTravelTimeSUV}
                                                                    disabled={!this.state.editableMode}
                                                                />
                                                            </div>
                                                            <div className={classes.description}>
                                                                {this.props.data.literalsReducer.RateCharts.WaitingTimeCharge} -
                                                                <span>Sedan</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur}
                                                                    name="waitingTimeChargeSedan"
                                                                    value={
                                                                        this.state.fixedRateChartDetails
                                                                            .waitingTimeChargeSedan
                                                                    }
                                                                    onChange={this.handleChange}
                                                                    error={this.state.errors.waitingTimeChargeSedan}
                                                                    disabled={!this.state.editableMode}
                                                                />
                                                                <span>SUV</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur}
                                                                    name="waitingTimeChargeSUV"
                                                                    value={
                                                                        this.state.fixedRateChartDetails
                                                                            .waitingTimeChargeSUV
                                                                    }
                                                                    onChange={this.handleChange}
                                                                    error={this.state.errors.waitingTimeChargeSUV}
                                                                    disabled={!this.state.editableMode}
                                                                />
                                                            </div>
                                                            <div className={classes.description}>
                                                                {this.props.data.literalsReducer.RateCharts.DiscountPercentage} -

                                                                <TextField
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur}
                                                                    name="discountPercentage"
                                                                    value={
                                                                        this.state.fixedRateChartDetails
                                                                            .discountPercentage
                                                                    }
                                                                    inputProps={{ maxLength: 5, className: "text-center" }}
                                                                    onChange={this.handleChange}
                                                                    error={this.state.errors.discountPercentage}
                                                                    disabled={!this.state.editableMode}
                                                                /> %
                                                            </div>
                                                        </TabContainer>}
                                                        {tabValue === 1 && <TabContainer>
                                                            {/* <div className={classes.description}>
                                                {this.props.data.literalsReducer.RateCharts.DiscountPercentage} -

                                                <TextField
                                                    className={classes.textField}
                                                    onBlur={this.handleBlur}
                                                    name="discountPercentageDuration"
                                                    value={
                                                        this.state.durationRateChartDetails
                                                            .discountPercentageDuration
                                                    }
                                                    inputProps={{ maxLength: 5, className: "text-center" }}
                                                    onChange={this.handleDurationRateChange}
                                                    error={this.state.errors.discountPercentageDuration}
                                                    disabled={!this.state.editableMode}
                                                /> %
                                            </div> */}
                                                            <div className={classes.description}>
                                                                {this.props.data.literalsReducer.RateCharts.BaseFare} -
                                                                <span>Sedan</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur}
                                                                    name="baseFareSedan"
                                                                    value={
                                                                        this.state.durationRateChartDetails
                                                                            .baseFareSedan
                                                                    }
                                                                    onChange={this.handleDurationRateChange}
                                                                    error={this.state.errors.baseFareSedan}
                                                                    disabled={!this.state.editableMode}
                                                                />
                                                                <span>SUV</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur}
                                                                    name="baseFareSUV"
                                                                    value={
                                                                        this.state.durationRateChartDetails
                                                                            .baseFareSUV
                                                                    }
                                                                    onChange={this.handleDurationRateChange}
                                                                    error={this.state.errors.baseFareSUV}
                                                                    disabled={!this.state.editableMode}
                                                                />
                                                            </div>
                                                            <div className={classes.description}>
                                                                {this.props.data.literalsReducer.RateCharts.ChargePerMinuteWaitingTime} -
                                                                <span>Sedan</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur}
                                                                    name="chargePerMinWaitingTimeSedan"
                                                                    value={
                                                                        this.state.durationRateChartDetails
                                                                            .chargePerMinWaitingTimeSedan
                                                                    }
                                                                    onChange={this.handleDurationRateChange}
                                                                    error={this.state.errors.chargePerMinWaitingTimeSedan}
                                                                    disabled={!this.state.editableMode}
                                                                />
                                                                <span>SUV</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur}
                                                                    name="chargePerMinWaitingTimeSUV"
                                                                    value={
                                                                        this.state.durationRateChartDetails
                                                                            .chargePerMinWaitingTimeSUV
                                                                    }
                                                                    onChange={this.handleDurationRateChange}
                                                                    error={this.state.errors.chargePerMinWaitingTimeSUV}
                                                                    disabled={!this.state.editableMode}
                                                                />
                                                            </div>
                                                            <Divider variant="middle" style={{ "margin": "25px 0" }} />
                                                            <h3>Hourly Booking</h3>
                                                            <div className={classes.description}>
                                                                {this.props.data.literalsReducer.RateCharts.PerHourBookingCost} -
                                                                <span>Sedan</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur}
                                                                    name="hourlyPerHourBookingSedan"
                                                                    value={
                                                                        this.state.durationRateChartDetails
                                                                            .hourlyPerHourBookingSedan
                                                                    }
                                                                    onChange={this.handleDurationRateChange}
                                                                    error={this.state.errors.hourlyPerHourBookingSedan}
                                                                    disabled={!this.state.editableMode}
                                                                />
                                                                <span>SUV</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur}
                                                                    name="hourlyPerHourBookingSUV"
                                                                    value={
                                                                        this.state.durationRateChartDetails
                                                                            .hourlyPerHourBookingSUV
                                                                    }
                                                                    onChange={this.handleDurationRateChange}
                                                                    error={this.state.errors.hourlyPerHourBookingSUV}
                                                                    disabled={!this.state.editableMode}
                                                                />
                                                            </div>
                                                            <div className={classes.description}>
                                                                {this.props.data.literalsReducer.RateCharts.PenaltyChargePerHour} -
                                                                <span>Sedan</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur}
                                                                    name="hourlyPenaltyChargeSedan"
                                                                    value={
                                                                        this.state.durationRateChartDetails
                                                                            .hourlyPenaltyChargeSedan
                                                                    }
                                                                    onChange={this.handleDurationRateChange}
                                                                    error={this.state.errors.hourlyPenaltyChargeSedan}
                                                                    disabled={!this.state.editableMode}
                                                                />
                                                                <span>SUV</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur}
                                                                    name="hourlyPenaltyChargeSUV"
                                                                    value={
                                                                        this.state.durationRateChartDetails
                                                                            .hourlyPenaltyChargeSUV
                                                                    }
                                                                    onChange={this.handleDurationRateChange}
                                                                    error={this.state.errors.hourlyPenaltyChargeSUV}
                                                                    disabled={!this.state.editableMode}
                                                                />
                                                            </div>
                                                            <div className={classes.description}>
                                                                For an excess <TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onInput={e => {
                                                                        e.target.value = e.target.value.replace(
                                                                            /[^0-9]/g,
                                                                            ""
                                                                        );
                                                                    }}
                                                                    name="hourlyExcessMin"
                                                                    value={
                                                                        this.state.durationRateChartDetails
                                                                            .hourlyExcessMin
                                                                    }
                                                                    onChange={this.handleDurationRateChange}
                                                                    error={this.state.errors.hourlyExcessMin}
                                                                    disabled={!this.state.editableMode}
                                                                />mins round to nearest 30 minutes with charge -
                                                                <span>Sedan</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur}
                                                                    name="hourlyExcessChargeSedan"
                                                                    value={
                                                                        this.state.durationRateChartDetails
                                                                            .hourlyExcessChargeSedan
                                                                    }
                                                                    onChange={this.handleDurationRateChange}
                                                                    error={this.state.errors.hourlyExcessChargeSedan}
                                                                    disabled={!this.state.editableMode}
                                                                />
                                                                <span>SUV</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur}
                                                                    name="hourlyExcessChargeSUV"
                                                                    value={
                                                                        this.state.durationRateChartDetails
                                                                            .hourlyExcessChargeSUV
                                                                    }
                                                                    onChange={this.handleDurationRateChange}
                                                                    error={this.state.errors.hourlyExcessChargeSUV}
                                                                    disabled={!this.state.editableMode}
                                                                />
                                                            </div>
                                                            <Divider variant="middle" style={{ "margin": "25px 0" }} />
                                                            <h3>Half Day Booking</h3>
                                                            <div className={classes.description}>
                                                                {this.props.data.literalsReducer.RateCharts.HalfdayBookingCost} -
                                                                <span>Sedan</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur}
                                                                    name="halfDayPerHourBookingSedan"
                                                                    value={
                                                                        this.state.durationRateChartDetails
                                                                            .halfDayPerHourBookingSedan
                                                                    }
                                                                    onChange={this.handleDurationRateChange}
                                                                    error={this.state.errors.halfDayPerHourBookingSedan}
                                                                    disabled={!this.state.editableMode}
                                                                />
                                                                <span>SUV</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur}
                                                                    name="halfDayPerHourBookingSUV"
                                                                    value={
                                                                        this.state.durationRateChartDetails
                                                                            .halfDayPerHourBookingSUV
                                                                    }
                                                                    onChange={this.handleDurationRateChange}
                                                                    error={this.state.errors.halfDayPerHourBookingSUV}
                                                                    disabled={!this.state.editableMode}
                                                                />
                                                            </div>
                                                            <div className={classes.description}>
                                                                {this.props.data.literalsReducer.RateCharts.PenaltyChargePerHour} -
                                                                <span>Sedan</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur}
                                                                    name="halfDayPenaltyChargeSedan"
                                                                    value={
                                                                        this.state.durationRateChartDetails
                                                                            .halfDayPenaltyChargeSedan
                                                                    }
                                                                    onChange={this.handleDurationRateChange}
                                                                    error={this.state.errors.halfDayPenaltyChargeSedan}
                                                                    disabled={!this.state.editableMode}
                                                                />
                                                                <span>SUV</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur}
                                                                    name="halfDayPenaltyChargeSUV"
                                                                    value={
                                                                        this.state.durationRateChartDetails
                                                                            .halfDayPenaltyChargeSUV
                                                                    }
                                                                    onChange={this.handleDurationRateChange}
                                                                    error={this.state.errors.halfDayPenaltyChargeSUV}
                                                                    disabled={!this.state.editableMode}
                                                                />
                                                            </div>
                                                            <div className={classes.description}>
                                                                For an excess <TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onInput={e => {
                                                                        e.target.value = e.target.value.replace(
                                                                            /[^0-9]/g,
                                                                            ""
                                                                        );
                                                                    }}
                                                                    name="halfDayExcessMin"
                                                                    value={
                                                                        this.state.durationRateChartDetails
                                                                            .halfDayExcessMin
                                                                    }
                                                                    onChange={this.handleDurationRateChange}
                                                                    error={this.state.errors.halfDayExcessMin}
                                                                    disabled={!this.state.editableMode}
                                                                />mins round to nearest 30 minutes with charge -
                                                                <span>Sedan</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur}
                                                                    name="halfDayExcessChargeSedan"
                                                                    value={
                                                                        this.state.durationRateChartDetails
                                                                            .halfDayExcessChargeSedan
                                                                    }
                                                                    onChange={this.handleDurationRateChange}
                                                                    error={this.state.errors.halfDayExcessChargeSedan}
                                                                    disabled={!this.state.editableMode}
                                                                />
                                                                <span>SUV</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur}
                                                                    name="halfDayExcessChargeSUV"
                                                                    value={
                                                                        this.state.durationRateChartDetails
                                                                            .halfDayExcessChargeSUV
                                                                    }
                                                                    onChange={this.handleDurationRateChange}
                                                                    error={this.state.errors.halfDayExcessChargeSUV}
                                                                    disabled={!this.state.editableMode}
                                                                />
                                                            </div>
                                                            <Divider variant="middle" style={{ "margin": "25px 0" }} />
                                                            <h3>Full Day Booking</h3>
                                                            <div className={classes.description}>
                                                                {this.props.data.literalsReducer.RateCharts.FulldayBookingCost} -
                                                                <span>Sedan</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur}
                                                                    name="fullDayPerHourBookingSedan"
                                                                    value={
                                                                        this.state.durationRateChartDetails
                                                                            .fullDayPerHourBookingSedan
                                                                    }
                                                                    onChange={this.handleDurationRateChange}
                                                                    error={this.state.errors.fullDayPerHourBookingSedan}
                                                                    disabled={!this.state.editableMode}
                                                                />
                                                                <span>SUV</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur}
                                                                    name="fullDayPerHourBookingSUV"
                                                                    value={
                                                                        this.state.durationRateChartDetails
                                                                            .fullDayPerHourBookingSUV
                                                                    }
                                                                    onChange={this.handleDurationRateChange}
                                                                    error={this.state.errors.fullDayPerHourBookingSUV}
                                                                    disabled={!this.state.editableMode}
                                                                />
                                                            </div>
                                                            <div className={classes.description}>
                                                                {this.props.data.literalsReducer.RateCharts.PenaltyChargePerHour} -
                                                                <span>Sedan</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur}
                                                                    name="fullDayPenaltyChargeSedan"
                                                                    value={
                                                                        this.state.durationRateChartDetails
                                                                            .fullDayPenaltyChargeSedan
                                                                    }
                                                                    onChange={this.handleDurationRateChange}
                                                                    error={this.state.errors.fullDayPenaltyChargeSedan}
                                                                    disabled={!this.state.editableMode}
                                                                />
                                                                <span>SUV</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur}
                                                                    name="fullDayPenaltyChargeSUV"
                                                                    value={
                                                                        this.state.durationRateChartDetails
                                                                            .fullDayPenaltyChargeSUV
                                                                    }
                                                                    onChange={this.handleDurationRateChange}
                                                                    error={this.state.errors.fullDayPenaltyChargeSUV}
                                                                    disabled={!this.state.editableMode}
                                                                />
                                                            </div>
                                                            <div className={classes.description}>
                                                                For an excess <TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onInput={e => {
                                                                        e.target.value = e.target.value.replace(
                                                                            /[^0-9]/g,
                                                                            ""
                                                                        );
                                                                    }}
                                                                    name="fullDayExcessMin"
                                                                    value={
                                                                        this.state.durationRateChartDetails
                                                                            .fullDayExcessMin
                                                                    }
                                                                    onChange={this.handleDurationRateChange}
                                                                    error={this.state.errors.fullDayExcessMin}
                                                                    disabled={!this.state.editableMode}
                                                                />mins round to nearest 30 minutes with charge -
                                                                <span>Sedan</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur}
                                                                    name="fullDayExcessChargeSedan"
                                                                    value={
                                                                        this.state.durationRateChartDetails
                                                                            .fullDayExcessChargeSedan
                                                                    }
                                                                    onChange={this.handleDurationRateChange}
                                                                    error={this.state.errors.fullDayExcessChargeSedan}
                                                                    disabled={!this.state.editableMode}
                                                                />
                                                                <span>SUV</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur}
                                                                    name="fullDayExcessChargeSUV"
                                                                    value={
                                                                        this.state.durationRateChartDetails
                                                                            .fullDayExcessChargeSUV
                                                                    }
                                                                    onChange={this.handleDurationRateChange}
                                                                    error={this.state.errors.fullDayExcessChargeSUV}
                                                                    disabled={!this.state.editableMode}
                                                                />
                                                            </div>
                                                        </TabContainer>}
                                                    </Grid>
                                                </Grid>
                                                {this.state.editableMode ?
                                                    <Grid container>
                                                        <Grid item xs={12} sm={12}>
                                                            <div className={classes.actionContainer}>
                                                                <Button
                                                                    size="large"
                                                                    color="default"
                                                                    variant="contained"
                                                                    className={classes.customMargin}
                                                                    onClick={this.resetForm}
                                                                >
                                                                    Cancel
                                                                </Button>
                                                                <Button
                                                                    size="large"
                                                                    color="primary"
                                                                    variant="outlined"
                                                                    className={classNames(classes.customMargin, classes.activeGoButton)}
                                                                    onClick={this.saveHandler}>
                                                                    Save
                                                                </Button>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                    : null}
                                            </Paper>
                                        </Grid>}
                                        {masterTabValue === 1 && <Grid item xs={12} sm={12}>
                                            <Paper>
                                                <Grid container className={classes.rightPaper}>
                                                    <Grid item xs={12} sm={12}>
                                                        <Tabs
                                                            value={tabValue1}
                                                            onChange={this.handleTabChange1}
                                                            className={classes.tabPan}
                                                            variant="fullWidth"
                                                        >
                                                            <Tab label={this.props.data.literalsReducer.RateCharts.FixedRides} />
                                                            <Tab label={this.props.data.literalsReducer.RateCharts.DurationBasedRides} />
                                                        </Tabs>
                                                        {tabValue1 === 0 && <TabContainer>
                                                            <div className={classes.description}>
                                                                {this.props.data.literalsReducer.RateCharts.BaseFare} -
                                                                <span>Sedan</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}

                                                                    name="baseFarePerKmSedan"
                                                                    value={
                                                                        this.state.fixedRateChartDetails1
                                                                            .baseFarePerKmSedan
                                                                    }
                                                                    onChange={this.handleChange1}
                                                                    onBlur={this.handleBlur1}
                                                                    error={this.state.errors1.baseFarePerKmSedan}
                                                                    disabled={!this.state.editableMode1}
                                                                />
                                                                <span>SUV</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur1}
                                                                    name="baseFarePerKmSUV"
                                                                    value={
                                                                        this.state.fixedRateChartDetails1
                                                                            .baseFarePerKmSUV
                                                                    }
                                                                    onChange={this.handleChange1}
                                                                    error={this.state.errors1.baseFarePerKmSUV}
                                                                    disabled={!this.state.editableMode1}
                                                                />
                                                            </div>
                                                            <div className={classes.description}>
                                                                {this.props.data.literalsReducer.RateCharts.ChargePerKm} -
                                                                <span>Sedan</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur1}
                                                                    name="chargePerKmSedan"
                                                                    value={
                                                                        this.state.fixedRateChartDetails1
                                                                            .chargePerKmSedan
                                                                    }
                                                                    onChange={this.handleChange1}
                                                                    error={this.state.errors1.chargePerKmSedan}
                                                                    disabled={!this.state.editableMode1}
                                                                />
                                                                <span>SUV</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur1}
                                                                    name="chargePerKmSUV"
                                                                    value={
                                                                        this.state.fixedRateChartDetails1
                                                                            .chargePerKmSUV
                                                                    }
                                                                    onChange={this.handleChange1}
                                                                    error={this.state.errors1.chargePerKmSUV}
                                                                    disabled={!this.state.editableMode1}
                                                                />
                                                            </div>
                                                            <div className={classes.description}>
                                                                {this.props.data.literalsReducer.RateCharts.ChargePerMinTravelTime} -
                                                                <span>Sedan</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur1}
                                                                    name="chargePerMinTravelTimeSedan"
                                                                    value={
                                                                        this.state.fixedRateChartDetails1
                                                                            .chargePerMinTravelTimeSedan
                                                                    }
                                                                    onChange={this.handleChange1}
                                                                    error={this.state.errors1.chargePerMinTravelTimeSedan}
                                                                    disabled={!this.state.editableMode1}
                                                                />
                                                                <span>SUV</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur1}
                                                                    name="chargePerMinTravelTimeSUV"
                                                                    value={
                                                                        this.state.fixedRateChartDetails1
                                                                            .chargePerMinTravelTimeSUV
                                                                    }
                                                                    onChange={this.handleChange1}
                                                                    error={this.state.errors1.chargePerMinTravelTimeSUV}
                                                                    disabled={!this.state.editableMode1}
                                                                />
                                                            </div>
                                                            <div className={classes.description}>
                                                                {this.props.data.literalsReducer.RateCharts.WaitingTimeCharge} -
                                                                <span>Sedan</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur1}
                                                                    name="waitingTimeChargeSedan"
                                                                    value={
                                                                        this.state.fixedRateChartDetails1
                                                                            .waitingTimeChargeSedan
                                                                    }
                                                                    onChange={this.handleChange1}
                                                                    error={this.state.errors1.waitingTimeChargeSedan}
                                                                    disabled={!this.state.editableMode1}
                                                                />
                                                                <span>SUV</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur1}
                                                                    name="waitingTimeChargeSUV"
                                                                    value={
                                                                        this.state.fixedRateChartDetails1
                                                                            .waitingTimeChargeSUV
                                                                    }
                                                                    onChange={this.handleChange1}
                                                                    error={this.state.errors1.waitingTimeChargeSUV}
                                                                    disabled={!this.state.editableMode1}
                                                                />
                                                            </div>
                                                            <div className={classes.description}>
                                                                {this.props.data.literalsReducer.RateCharts.DiscountPercentage} -

                                                                <TextField
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur1}
                                                                    name="discountPercentage"
                                                                    value={
                                                                        this.state.fixedRateChartDetails1
                                                                            .discountPercentage
                                                                    }
                                                                    inputProps={{ maxLength: 5, className: "text-center" }}
                                                                    onChange={this.handleChange1}
                                                                    error={this.state.errors1.discountPercentage}
                                                                    disabled={!this.state.editableMode1}
                                                                /> %
                                                            </div>
                                                        </TabContainer>}
                                                        {tabValue1 === 1 && <TabContainer>
                                                            {/* <div className={classes.description}>
                                                {this.props.data.literalsReducer.RateCharts.DiscountPercentage} -

                                                <TextField
                                                    className={classes.textField}
                                                    onBlur={this.handleBlur}
                                                    name="discountPercentageDuration"
                                                    value={
                                                        this.state.durationRateChartDetails
                                                            .discountPercentageDuration
                                                    }
                                                    inputProps={{ maxLength: 5, className: "text-center" }}
                                                    onChange={this.handleDurationRateChange}
                                                    error={this.state.errors.discountPercentageDuration}
                                                    disabled={!this.state.editableMode}
                                                /> %
                                            </div> */}
                                                            <div className={classes.description}>
                                                                {this.props.data.literalsReducer.RateCharts.BaseFare} -
                                                                <span>Sedan</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur1}
                                                                    name="baseFareSedan"
                                                                    value={
                                                                        this.state.durationRateChartDetails1
                                                                            .baseFareSedan
                                                                    }
                                                                    onChange={this.handleDurationRateChange1}
                                                                    error={this.state.errors1.baseFareSedan}
                                                                    disabled={!this.state.editableMode1}
                                                                />
                                                                <span>SUV</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur1}
                                                                    name="baseFareSUV"
                                                                    value={
                                                                        this.state.durationRateChartDetails1
                                                                            .baseFareSUV
                                                                    }
                                                                    onChange={this.handleDurationRateChange1}
                                                                    error={this.state.errors1.baseFareSUV}
                                                                    disabled={!this.state.editableMode1}
                                                                />
                                                            </div>
                                                            <div className={classes.description}>
                                                                {this.props.data.literalsReducer.RateCharts.ChargePerMinuteWaitingTime} -
                                                                <span>Sedan</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur1}
                                                                    name="chargePerMinWaitingTimeSedan"
                                                                    value={
                                                                        this.state.durationRateChartDetails1
                                                                            .chargePerMinWaitingTimeSedan
                                                                    }
                                                                    onChange={this.handleDurationRateChange1}
                                                                    error={this.state.errors1.chargePerMinWaitingTimeSedan}
                                                                    disabled={!this.state.editableMode1}
                                                                />
                                                                <span>SUV</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur1}
                                                                    name="chargePerMinWaitingTimeSUV"
                                                                    value={
                                                                        this.state.durationRateChartDetails1
                                                                            .chargePerMinWaitingTimeSUV
                                                                    }
                                                                    onChange={this.handleDurationRateChange1}
                                                                    error={this.state.errors1.chargePerMinWaitingTimeSUV}
                                                                    disabled={!this.state.editableMode1}
                                                                />
                                                            </div>
                                                            <Divider variant="middle" style={{ "margin": "25px 0" }} />
                                                            <h3>Hourly Booking</h3>
                                                            <div className={classes.description}>
                                                                {this.props.data.literalsReducer.RateCharts.PerHourBookingCost} -
                                                                <span>Sedan</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur1}
                                                                    name="hourlyPerHourBookingSedan"
                                                                    value={
                                                                        this.state.durationRateChartDetails1
                                                                            .hourlyPerHourBookingSedan
                                                                    }
                                                                    onChange={this.handleDurationRateChange1}
                                                                    error={this.state.errors1.hourlyPerHourBookingSedan}
                                                                    disabled={!this.state.editableMode1}
                                                                />
                                                                <span>SUV</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur1}
                                                                    name="hourlyPerHourBookingSUV"
                                                                    value={
                                                                        this.state.durationRateChartDetails1
                                                                            .hourlyPerHourBookingSUV
                                                                    }
                                                                    onChange={this.handleDurationRateChange1}
                                                                    error={this.state.errors1.hourlyPerHourBookingSUV}
                                                                    disabled={!this.state.editableMode1}
                                                                />
                                                            </div>
                                                            <div className={classes.description}>
                                                                {this.props.data.literalsReducer.RateCharts.PenaltyChargePerHour} -
                                                                <span>Sedan</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur1}
                                                                    name="hourlyPenaltyChargeSedan"
                                                                    value={
                                                                        this.state.durationRateChartDetails1
                                                                            .hourlyPenaltyChargeSedan
                                                                    }
                                                                    onChange={this.handleDurationRateChange1}
                                                                    error={this.state.errors1.hourlyPenaltyChargeSedan}
                                                                    disabled={!this.state.editableMode1}
                                                                />
                                                                <span>SUV</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur1}
                                                                    name="hourlyPenaltyChargeSUV"
                                                                    value={
                                                                        this.state.durationRateChartDetails1
                                                                            .hourlyPenaltyChargeSUV
                                                                    }
                                                                    onChange={this.handleDurationRateChange1}
                                                                    error={this.state.errors1.hourlyPenaltyChargeSUV}
                                                                    disabled={!this.state.editableMode1}
                                                                />
                                                            </div>
                                                            <div className={classes.description}>
                                                                For an excess <TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onInput={e => {
                                                                        e.target.value = e.target.value.replace(
                                                                            /[^0-9]/g,
                                                                            ""
                                                                        );
                                                                    }}
                                                                    name="hourlyExcessMin"
                                                                    value={
                                                                        this.state.durationRateChartDetails1
                                                                            .hourlyExcessMin
                                                                    }
                                                                    onChange={this.handleDurationRateChange1}
                                                                    error={this.state.errors1.hourlyExcessMin}
                                                                    disabled={!this.state.editableMode1}
                                                                />mins round to nearest 30 minutes with charge -
                                                                <span>Sedan</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur1}
                                                                    name="hourlyExcessChargeSedan"
                                                                    value={
                                                                        this.state.durationRateChartDetails1
                                                                            .hourlyExcessChargeSedan
                                                                    }
                                                                    onChange={this.handleDurationRateChange1}
                                                                    error={this.state.errors1.hourlyExcessChargeSedan}
                                                                    disabled={!this.state.editableMode1}
                                                                />
                                                                <span>SUV</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur1}
                                                                    name="hourlyExcessChargeSUV"
                                                                    value={
                                                                        this.state.durationRateChartDetails1
                                                                            .hourlyExcessChargeSUV
                                                                    }
                                                                    onChange={this.handleDurationRateChange1}
                                                                    error={this.state.errors1.hourlyExcessChargeSUV}
                                                                    disabled={!this.state.editableMode1}
                                                                />
                                                            </div>
                                                            <Divider variant="middle" style={{ "margin": "25px 0" }} />
                                                            <h3>Half Day Booking</h3>
                                                            <div className={classes.description}>
                                                                {this.props.data.literalsReducer.RateCharts.HalfdayBookingCost} -
                                                                <span>Sedan</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur1}
                                                                    name="halfDayPerHourBookingSedan"
                                                                    value={
                                                                        this.state.durationRateChartDetails1
                                                                            .halfDayPerHourBookingSedan
                                                                    }
                                                                    onChange={this.handleDurationRateChange1}
                                                                    error={this.state.errors1.halfDayPerHourBookingSedan}
                                                                    disabled={!this.state.editableMode1}
                                                                />
                                                                <span>SUV</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur1}
                                                                    name="halfDayPerHourBookingSUV"
                                                                    value={
                                                                        this.state.durationRateChartDetails1
                                                                            .halfDayPerHourBookingSUV
                                                                    }
                                                                    onChange={this.handleDurationRateChange1}
                                                                    error={this.state.errors1.halfDayPerHourBookingSUV}
                                                                    disabled={!this.state.editableMode1}
                                                                />
                                                            </div>
                                                            <div className={classes.description}>
                                                                {this.props.data.literalsReducer.RateCharts.PenaltyChargePerHour} -
                                                                <span>Sedan</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur1}
                                                                    name="halfDayPenaltyChargeSedan"
                                                                    value={
                                                                        this.state.durationRateChartDetails1
                                                                            .halfDayPenaltyChargeSedan
                                                                    }
                                                                    onChange={this.handleDurationRateChange1}
                                                                    error={this.state.errors1.halfDayPenaltyChargeSedan}
                                                                    disabled={!this.state.editableMode1}
                                                                />
                                                                <span>SUV</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur1}
                                                                    name="halfDayPenaltyChargeSUV"
                                                                    value={
                                                                        this.state.durationRateChartDetails1
                                                                            .halfDayPenaltyChargeSUV
                                                                    }
                                                                    onChange={this.handleDurationRateChange1}
                                                                    error={this.state.errors1.halfDayPenaltyChargeSUV}
                                                                    disabled={!this.state.editableMode1}
                                                                />
                                                            </div>
                                                            <div className={classes.description}>
                                                                For an excess <TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onInput={e => {
                                                                        e.target.value = e.target.value.replace(
                                                                            /[^0-9]/g,
                                                                            ""
                                                                        );
                                                                    }}
                                                                    name="halfDayExcessMin"
                                                                    value={
                                                                        this.state.durationRateChartDetails1
                                                                            .halfDayExcessMin
                                                                    }
                                                                    onChange={this.handleDurationRateChange1}
                                                                    error={this.state.errors1.halfDayExcessMin}
                                                                    disabled={!this.state.editableMode1}
                                                                />mins round to nearest 30 minutes with charge -
                                                                <span>Sedan</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur1}
                                                                    name="halfDayExcessChargeSedan"
                                                                    value={
                                                                        this.state.durationRateChartDetails1
                                                                            .halfDayExcessChargeSedan
                                                                    }
                                                                    onChange={this.handleDurationRateChange1}
                                                                    error={this.state.errors1.halfDayExcessChargeSedan}
                                                                    disabled={!this.state.editableMode1}
                                                                />
                                                                <span>SUV</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur1}
                                                                    name="halfDayExcessChargeSUV"
                                                                    value={
                                                                        this.state.durationRateChartDetails1
                                                                            .halfDayExcessChargeSUV
                                                                    }
                                                                    onChange={this.handleDurationRateChange1}
                                                                    error={this.state.errors1.halfDayExcessChargeSUV}
                                                                    disabled={!this.state.editableMode1}
                                                                />
                                                            </div>
                                                            <Divider variant="middle" style={{ "margin": "25px 0" }} />
                                                            <h3>Full Day Booking</h3>
                                                            <div className={classes.description}>
                                                                {this.props.data.literalsReducer.RateCharts.FulldayBookingCost} -
                                                                <span>Sedan</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur1}
                                                                    name="fullDayPerHourBookingSedan"
                                                                    value={
                                                                        this.state.durationRateChartDetails1
                                                                            .fullDayPerHourBookingSedan
                                                                    }
                                                                    onChange={this.handleDurationRateChange1}
                                                                    error={this.state.errors1.fullDayPerHourBookingSedan}
                                                                    disabled={!this.state.editableMode1}
                                                                />
                                                                <span>SUV</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur1}
                                                                    name="fullDayPerHourBookingSUV"
                                                                    value={
                                                                        this.state.durationRateChartDetails1
                                                                            .fullDayPerHourBookingSUV
                                                                    }
                                                                    onChange={this.handleDurationRateChange1}
                                                                    error={this.state.errors1.fullDayPerHourBookingSUV}
                                                                    disabled={!this.state.editableMode1}
                                                                />
                                                            </div>
                                                            <div className={classes.description}>
                                                                {this.props.data.literalsReducer.RateCharts.PenaltyChargePerHour} -
                                                                <span>Sedan</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur1}
                                                                    name="fullDayPenaltyChargeSedan"
                                                                    value={
                                                                        this.state.durationRateChartDetails1
                                                                            .fullDayPenaltyChargeSedan
                                                                    }
                                                                    onChange={this.handleDurationRateChange1}
                                                                    error={this.state.errors1.fullDayPenaltyChargeSedan}
                                                                    disabled={!this.state.editableMode1}
                                                                />
                                                                <span>SUV</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur1}
                                                                    name="fullDayPenaltyChargeSUV"
                                                                    value={
                                                                        this.state.durationRateChartDetails1
                                                                            .fullDayPenaltyChargeSUV
                                                                    }
                                                                    onChange={this.handleDurationRateChange1}
                                                                    error={this.state.errors1.fullDayPenaltyChargeSUV}
                                                                    disabled={!this.state.editableMode1}
                                                                />
                                                            </div>
                                                            <div className={classes.description}>
                                                                For an excess <TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onInput={e => {
                                                                        e.target.value = e.target.value.replace(
                                                                            /[^0-9]/g,
                                                                            ""
                                                                        );
                                                                    }}
                                                                    name="fullDayExcessMin"
                                                                    value={
                                                                        this.state.durationRateChartDetails1
                                                                            .fullDayExcessMin
                                                                    }
                                                                    onChange={this.handleDurationRateChange1}
                                                                    error={this.state.errors1.fullDayExcessMin}
                                                                    disabled={!this.state.editableMode1}
                                                                />mins round to nearest 30 minutes with charge -
                                                                <span>Sedan</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur1}
                                                                    name="fullDayExcessChargeSedan"
                                                                    value={
                                                                        this.state.durationRateChartDetails1
                                                                            .fullDayExcessChargeSedan
                                                                    }
                                                                    onChange={this.handleDurationRateChange1}
                                                                    error={this.state.errors1.fullDayExcessChargeSedan}
                                                                    disabled={!this.state.editableMode1}
                                                                />
                                                                <span>SUV</span>
                                                                &#36;<TextField
                                                                    inputProps={{ className: "text-center", maxLength: 4 }}
                                                                    className={classes.textField}
                                                                    onBlur={this.handleBlur}
                                                                    name="fullDayExcessChargeSUV"
                                                                    value={
                                                                        this.state.durationRateChartDetails1
                                                                            .fullDayExcessChargeSUV
                                                                    }
                                                                    onChange={this.handleDurationRateChange1}
                                                                    error={this.state.errors1.fullDayExcessChargeSUV}
                                                                    disabled={!this.state.editableMode1}
                                                                />
                                                            </div>
                                                        </TabContainer>}
                                                    </Grid>
                                                </Grid>
                                                {this.state.editableMode1 ?
                                                    <Grid container>
                                                        <Grid item xs={12} sm={12}>
                                                            <div className={classes.actionContainer}>
                                                                <Button
                                                                    size="large"
                                                                    color="default"
                                                                    variant="contained"
                                                                    className={classes.customMargin}
                                                                    onClick={this.resetForm}
                                                                >
                                                                    Cancel
                                                                </Button>
                                                                <Button
                                                                    size="large"
                                                                    color="primary"
                                                                    variant="outlined"
                                                                    className={classNames(classes.customMargin, classes.activeGoButton)}
                                                                    onClick={this.saveHandler1}>
                                                                    Save
                                                                </Button>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                    : null}
                                            </Paper>
                                        </Grid>}


                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>






                    </Grid>
                </div>
            </MuiThemeProvider>
        );
    }
}

const mapStateToProps = state => {
    return { data: state };
};

const mapDispatchToProps = dispatch => {
    return {
        getRateChart: () => dispatch(getRateChart()),
        fixedRateChartDataUpdatedDone: () => dispatch(fixedRateChartDataUpdatedDone()),
        durationRateChartDataUpdatedDone: () => dispatch(durationRateChartDataUpdatedDone()),
        resetDetails: () => dispatch(resetDetails()),
        resetDetails1: () => dispatch(resetDetails1()),
        updateFixedRateChart: (payload) => dispatch(updateFixedRateChart(payload)),
        updateFixedRateChartForSapphire: (payload) => dispatch(updateFixedRateChartForSapphire(payload)),
        updateDurationRateChart: (payload) => dispatch(updateDurationRateChart(payload)),
        updateDurationRateChartForSapphire: (payload) => dispatch(updateDurationRateChartForSapphire(payload)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(
    styles,
    theme => ({
        ...styles(theme),
        ...contentStyles(theme),
        ...globalstyles(theme)
    }),
    { withTheme: true }
)(RateCharts));