/*global google*/
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import classNames from "classnames";
import PropTypes from 'prop-types';
import ScrollArea from "react-scrollbar";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Chip from '@material-ui/core/Chip';
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import theme from "../../../../app/theme";
import globalstyles from "../../../../globalstyles";
import {
  withGoogleMap, GoogleMap, Marker, DirectionsRenderer
} from "react-google-maps";
import DemoUserImage from "../../../../assets/images/default-profile-pic.png";
import contentStyles from "../../../auth-layout/content.styles";
import styles from "./styles";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import StarRatings from "react-star-ratings";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import Icon from '@material-ui/core/Icon';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import Timer from 'react-compound-timer';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBack";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import Typography from "@material-ui/core/Typography";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faStar, faUpload
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MapComponent from "../../../../components/Map";
import {
  fetchTrip, resetDetails,
  approveAdditionalChargeRequest,
  rejectAdditionalChargeRequest,
  approveNoShowRequest,
  rejectNoShowRequest,
  noShowRejectedDone,
  noShowApprovedDone,
  additionalChargeApprovedDone,
  additionalChargeRejectedDone,
  endTrip,
  endTripDone
} from "../../../../store/actions/ongoingTrip";
import CountryCodesConfig from "../../../../config/country-codes";
import moment from "moment";
import Geocode from "react-geocode";
import {
  OngoingTripStatusObject,
  TripTypeObject,
  ActivityList,
  RiderActivityList,
  DriverActivityList,
  AdminActivityList
} from "../../../../config/trip";
library.add(faStar, faUpload);


function TabContainer({ children }) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired
};

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`
  };
}
const modalStyles = theme => ({
  modalPaper: {
    position: "absolute",
    width: theme.spacing.unit * 60,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: "none",
    "& $profileImagePlaceholder": {
      margin: "0 auto 20px"
    },
    "& $profileImageUploaderContainer": {
      left: "0",
      right: "0",
      margin: "auto"
    }
  }
});

class OngoingTripDetails extends Component {
  state = {
    frmPage: "ongoingTripDetail",
    originLocation: {},
    destinationLocation: {},
    zonePlotted: false,
    directions: null,
    single: null,
    approveNoShowModalOpen: false,
    rejectNoShowModalOpen: false,
    approveAdditionalChargeModalOpen: false,
    rejectAdditionalChargeModalOpen: false,
    endTripModalOpen: false,
    endTripAddress: "",
    currentAdditionalChargeVal: 0,
    editAdditionalChargeVal: 0,
    labelWidth: 0,
    tripDetails: {
      riderName: "",
      riderAvgRating: 0,
      riderEmail: "",
      riderPhoneNumber: "",
      riderId: "",
      driverName: "",
      driverAvgRating: 0,
      driverEmail: "",
      driverPhoneNumber: "",
      driverId: "",
      driverVehicleType: "",
      driverVehicleMake: "",
      driverVehicleModel: "",
      driverVehicleColor: "",
      tripReqBookingId: "",
      tripType: "Fixed",
      noOfPassenger: 0,
      tripReqTime: "",
      pickupTimeRequested: "",
      pickupTimeActual: "",
      pickupLocationRequested: "",
      pickupLocationActual: "",
      dropAddressRequested: "",
      dropAddressActual: "",
      destinationAddressActual: "",
      dropOffTime: "",
      dropOffTimeRequestedSecond: "",
      tripStatus: 0,
      travelDistance: 0,
      waitingTime: 0,
      travelTime: 0,
      suvFare: 0,
      sedanFare: 0,
      paymentStatus: "",
      activityList: [],
      alerts: [],
      notificationContacts: [],
      isBusinessTrip: false,
      additionalComment: null,
      airline: null,
      flightNumber: null
    },
    errors: {},
    haveImage: false,
    editableMode: false,
    tabValue: 0
  };

  tripTypeList = TripTypeObject;

  statusList = OngoingTripStatusObject;

  activityLogMessageList = ActivityList;

  vehicleTypes = {
    1: "Sedan",
    2: "SUV"
  };

  alertTypes = {
    5: "Additional charge request",
    6: "No show request"
  };

  riderActivities = RiderActivityList;
  driverActivities = DriverActivityList;
  adminActivities = AdminActivityList;

  componentDidMount() {


    Geocode.setApiKey("AIzaSyB94spWB-d2PtydTxlj55M1T2xI0J5eq7M");
    let localStorageData = window.localStorage.upcAuthData;
    if (localStorageData && JSON.parse(localStorageData)) {
      if (!JSON.parse(localStorageData).user.roleInfo.isSuperAdmin) {
        JSON.parse(localStorageData).user.roleInfo.roleId.permissions.forEach(
          each => {
            if (each.moduleName === "ongoingTrip") {
              if (each.role === 2) {
                this.setState({
                  ...this.state,
                  editableMode: true,
                  loading: true
                });
              } else {
                this.setState({
                  ...this.state,
                  loading: true
                });
              }
            }
          }
        );
      } else {
        this.setState({
          ...this.state,
          editableMode: true,
          loading: true
        });
      }
    }


    this.props.fetchTrip(this.props.match.params.tripId);
  }

  formatNumber(number) {
    let str1 = "(";
    for (let i = 0; i < number.length; i++) {
      str1 += number[i];

      if (i === 2) {
        str1 += ") ";
      }
      if (i === 5) {
        str1 += "-";
      }
    }
    return str1;
  }

  filterChange = event => {

    let errors = { ...this.state.errors };
    errors[event.target.name] = false;

    if (event.target.name === "additionlCharge") {
      errors.invalidAdditionalCharge = false;
    }

    this.setState({ ...this.state, additionalChargeVal: event.target.value, errors });
  };

  timeDifference = (date1, date2) => {
    var difference = date1 - date2;

    var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
    difference -= daysDifference * 1000 * 60 * 60 * 24

    var hoursDifference = Math.floor(difference / 1000 / 60 / 60);
    difference -= hoursDifference * 1000 * 60 * 60

    var minutesDifference = Math.floor(difference / 1000 / 60);
    difference -= minutesDifference * 1000 * 60

    // var secondsDifference = Math.floor(difference/1000);
    // console.log(minutesDifference,"minutesDifference")
    let strTimeDiff = "";
    if (daysDifference !== 0) {
      strTimeDiff += daysDifference + ' day '
    }
    if (hoursDifference !== 0) {
      strTimeDiff += hoursDifference + ' hour '
    }
    if (minutesDifference !== 0) {
      strTimeDiff += minutesDifference + ' minute '
    }

    return strTimeDiff
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.data.notificationsReducer.mqttComing) {
      // console.log(this.props.data.notificationsReducer.mqttNotification)
      let tmpMqttdata = this.props.data.notificationsReducer.mqttNotification
      let tmpMqttdataType = tmpMqttdata.type
      // console.log(tmpMqttdata.data,"this.props.match.params.tripId",this.props.match.params.tripId)
      if (tmpMqttdataType === 5 || tmpMqttdataType === 6) {
        if (tmpMqttdata.data._id === this.props.match.params.tripId) {
          this.setState({ ...this.state, detailsFetched: false })
          this.props.fetchTrip(this.props.match.params.tripId);
        }

      }
    }
    // console.log(this.props.data.ongoingTripReducer.tripDetails,"this.props.data.ongoingTripReducer.tripDetails")
    if (this.props.data.ongoingTripReducer.tripDetails &&
      Object.keys(this.props.data.ongoingTripReducer.tripDetails).length) {
      if (this.props.data.ongoingTripReducer.tripDetails.tripStatus !== 5 &&
        this.props.data.ongoingTripReducer.tripDetails.tripStatus !== 6 &&
        this.props.data.ongoingTripReducer.tripDetails.tripStatus !== 7 &&
        this.props.data.ongoingTripReducer.tripDetails.tripStatus !== 11 &&
        this.props.data.ongoingTripReducer.tripDetails.tripStatus !== 12) {
        this.props.history.push("/web/auth/ongoing-trips");
      }
    }
    if (
      !this.state.detailsFetched &&
      Object.keys(this.props.data.ongoingTripReducer.tripDetails).length ||
      (this.props.data.ongoingTripReducer.isFetching &&
        this.props.data.notificationsReducer.mqttComing &&
        (this.props.data.notificationsReducer.mqttNotification.type === 6
          || this.props.data.notificationsReducer.mqttNotification.type === 5
        ) &&
        this.props.data.notificationsReducer.mqttNotification.data &&
        this.props.data.notificationsReducer.mqttNotification.data._id === this.props.match.params.tripId)
    ) {
      //  console.log('detailsFetched ',this.props.data.ongoingTripReducer.tripDetails)
      let localTripData = { ...this.state.tripDetails };
      localTripData.riderProfilePicture = this.props.data.ongoingTripReducer.tripDetails.rider.profilePicture;

      localTripData.riderName = this.props.data.ongoingTripReducer.tripDetails.rider.fullName || "N/A";
      localTripData.riderAvgRating = this.props.data.ongoingTripReducer.tripDetails.rider.ratingData && this.props.data.ongoingTripReducer.tripDetails.rider.ratingData.total ?
        (this.props.data.ongoingTripReducer.tripDetails.rider.ratingData.total / this.props.data.ongoingTripReducer.tripDetails.rider.ratingData.count).toFixed(1) : 0;
      localTripData.riderEmail = this.props.data.ongoingTripReducer.tripDetails.rider.email || "N/A";
      localTripData.riderPhoneNumber = this.props.data.ongoingTripReducer.tripDetails.rider.phone ?
        CountryCodesConfig.filter(item => {
          return item.code === this.props.data.ongoingTripReducer.tripDetails.rider.phone.countryCode;
        })[0].dial_code + this.formatNumber(this.props.data.ongoingTripReducer.tripDetails.rider.phone.number) : "N/A";
      localTripData.riderId = this.props.data.ongoingTripReducer.tripDetails.rider.displayId || "N/A";

      localTripData.driverProfilePicture = this.props.data.ongoingTripReducer.tripDetails.assignedDriverInfo.driver.profilePicture;
      localTripData.driverName = this.props.data.ongoingTripReducer.tripDetails.assignedDriverInfo.driver.fullName || "N/A";
      localTripData.driverAvgRating = this.props.data.ongoingTripReducer.tripDetails.assignedDriverInfo.driver.ratingData &&
        this.props.data.ongoingTripReducer.tripDetails.assignedDriverInfo.driver.ratingData.total ?
        (this.props.data.ongoingTripReducer.tripDetails.assignedDriverInfo.driver.ratingData.total / this.props.data.ongoingTripReducer.tripDetails.assignedDriverInfo.driver.ratingData.count).toFixed(1) : 0;
      localTripData.driverEmail = this.props.data.ongoingTripReducer.tripDetails.assignedDriverInfo.driver.email || "N/A";
      localTripData.driverPhoneNumber = this.props.data.ongoingTripReducer.tripDetails.assignedDriverInfo.driver.phone ?
        CountryCodesConfig.filter(item => {
          return item.code === this.props.data.ongoingTripReducer.tripDetails.assignedDriverInfo.driver.phone.countryCode;
        })[0].dial_code +
        this.formatNumber(this.props.data.ongoingTripReducer.tripDetails.assignedDriverInfo.driver.phone.number) : "N/A";
      localTripData.driverId = this.props.data.ongoingTripReducer.tripDetails.assignedDriverInfo.driver.displayId || "N/A";

      localTripData.driverVehicleType = this.props.data.ongoingTripReducer.tripDetails.assignedDriverInfo.driver.vehicleInfo ? this.vehicleTypes[this.props.data.ongoingTripReducer.tripDetails.assignedDriverInfo.driver.vehicleInfo.vehicleType] : "N/A";
      localTripData.driverVehicleMake = this.props.data.ongoingTripReducer.tripDetails.assignedDriverInfo.driver.vehicleInfo ? this.props.data.ongoingTripReducer.tripDetails.assignedDriverInfo.driver.vehicleInfo.vehicleMake : "N/A";
      localTripData.driverVehicleModel = this.props.data.ongoingTripReducer.tripDetails.assignedDriverInfo.driver.vehicleInfo ? this.props.data.ongoingTripReducer.tripDetails.assignedDriverInfo.driver.vehicleInfo.vehicleModel : "N/A";
      localTripData.driverVehicleColor = this.props.data.ongoingTripReducer.tripDetails.assignedDriverInfo.driver.vehicleInfo ? this.props.data.ongoingTripReducer.tripDetails.assignedDriverInfo.driver.vehicleInfo.vehicleColor : null;
      localTripData.driverRateChartType = this.props.data.pastTripReducer.tripDetails.assignedDriverInfo.driver.rateChartType || "N/A";

      localTripData.tripReqBookingId = this.props.data.ongoingTripReducer.tripDetails.displayId || "N/A";

      localTripData.tripType = this.tripTypeList[this.props.data.ongoingTripReducer.tripDetails.tripType] || "N/A";
      localTripData.noOfPassenger = this.props.data.ongoingTripReducer.tripDetails.requestedNumberOfSeats || "0";
      if (this.props.data.ongoingTripReducer.tripDetails.bookingTime) {
        localTripData.tripReqTime = moment(this.props.data.ongoingTripReducer.tripDetails.bookingTime).format(
          "MM-DD-YYYY, h:mm a"
        );
      } else {
        localTripData.tripReqTime = "";
      }
      if (this.props.data.ongoingTripReducer.tripDetails.pickupTime.requested) {
        localTripData.pickupTimeRequested = moment(this.props.data.ongoingTripReducer.tripDetails.pickupTime.requested).format(
          "MM-DD-YYYY, h:mm a"
        );
      } else {
        localTripData.pickupTimeRequested = "";
      }
      if (this.props.data.ongoingTripReducer.tripDetails.pickupTime.actual) {
        localTripData.pickupTimeActual = moment(this.props.data.ongoingTripReducer.tripDetails.pickupTime.actual).format(
          "MM-DD-YYYY, h:mm a"
        );
      } else {
        localTripData.pickupTimeActual = "";
      }

      localTripData.pickupLocationRequested = this.props.data.ongoingTripReducer.tripDetails.pickupAddress.requested || "N/A";
      localTripData.pickupLocationActual = this.props.data.ongoingTripReducer.tripDetails.pickupAddress.actual || "N/A";

      localTripData.dropAddressRequested = this.props.data.ongoingTripReducer.tripDetails.dropAddress.requested || "N/A";
      localTripData.dropAddressActual = this.props.data.ongoingTripReducer.tripDetails.dropAddress.actual || "N/A";

      localTripData.destinationAddressActual = this.props.data.ongoingTripReducer.tripDetails.dropAddress.actual || "N/A"; //it needs to be handled depending on the trip type

      if (this.props.data.ongoingTripReducer.tripDetails.dropTime.actual) {
        localTripData.dropOffTime = moment(this.props.data.ongoingTripReducer.tripDetails.dropTime.actual).format(
          "MM-DD-YYYY, h:mm a"
        );
      } else {
        localTripData.dropOffTime = "";
      }

      localTripData.tripStatus = this.props.data.ongoingTripReducer.tripDetails.tripStatus || "N/A";

      localTripData.travelDistance = this.props.data.ongoingTripReducer.tripDetails.travelDistance.actual || "0";
      localTripData.waitingTime = this.props.data.ongoingTripReducer.tripDetails.waitingTime || "0";


      if (this.props.data.ongoingTripReducer.tripDetails.dropTime.actual && this.props.data.ongoingTripReducer.tripDetails.pickupTime.actual) {
        // console.log(new Date(this.props.data.ongoingTripReducer.tripDetails.dropTime.actual).getTime(),"pickupActual")
        // console.log(new Date(this.props.data.ongoingTripReducer.tripDetails.dropTime.actual) - new Date(this.props.data.ongoingTripReducer.tripDetails.pickupTime.actual))

        let localPickupActual = new Date(this.props.data.ongoingTripReducer.tripDetails.pickupTime.actual).getTime();
        let localDropActual = new Date(this.props.data.ongoingTripReducer.tripDetails.dropTime.actual).getTime();

        let differenceTime = this.timeDifference(localDropActual, localPickupActual)


        // localTripData.travelTime = Math.ceil((new Date(this.props.data.ongoingTripReducer.tripDetails.dropTime.actual) - new Date(this.props.data.billingReducer.billingDetails.pickupTime.actual)) / 3600000)
        localTripData.travelTime = differenceTime;
      } else {
        localTripData.travelTime = "0";
      }



      let currentTime = new Date().getTime();
      let tmpDropRequested = new Date(this.props.data.ongoingTripReducer.tripDetails.dropTime.requested);
      let dropRequested = tmpDropRequested.getTime()
      console.log(currentTime, "####", dropRequested)
      localTripData.dropOffTimeRequestedSecond = Math.round((currentTime - dropRequested))
      console.log(Math.round((currentTime - dropRequested)), "Math.round((currentTime - dropRequested)/1000)")
      //calculating SUV & Sedan fare
      let fareChartInfo = this.props.data.ongoingTripReducer.tripDetails.fareChartInfo;
      let dropTime = this.props.data.ongoingTripReducer.tripDetails.dropTime;
      let pickupTime = this.props.data.ongoingTripReducer.tripDetails.pickupTime;
      let travelDistance = this.props.data.ongoingTripReducer.tripDetails.travelDistance;

      // if (this.props.data.ongoingTripReducer.tripDetails.tripType === 1 || this.props.data.ongoingTripReducer.tripDetails.tripType === 2) {
      //   localTripData.suvFare = fareChartInfo.fixed.baseFare.suv + Math.ceil((new Date(dropTime.requested) -
      //     new Date(pickupTime.requested)) / 60000) * fareChartInfo.fixed.chargePerMinute.suv
      //     + travelDistance.requested * fareChartInfo.fixed.chargePerDistanceUnit.suv;
      //   localTripData.sedanFare = fareChartInfo.fixed.baseFare.sedan + Math.ceil((new Date(dropTime.requested) -
      //     new Date(pickupTime.requested)) / 60000) * fareChartInfo.fixed.chargePerMinute.sedan
      //     + travelDistance.requested * fareChartInfo.fixed.chargePerDistanceUnit.sedan;
      // } else {
      //   localTripData.suvFare = fareChartInfo.duration.baseFare + this.props.data.ongoingTripReducer.tripDetails.requestedNumberOfHours * fareChartInfo.duration.hourly.perHourCost.suv;
      //   localTripData.sedanFare = fareChartInfo.duration.baseFare + this.props.data.ongoingTripReducer.tripDetails.requestedNumberOfHours * fareChartInfo.duration.hourly.perHourCost.sedan;
      // }

      switch (this.props.data.ongoingTripReducer.tripDetails.tripType) {
        case 1:
          localTripData.suvFare = fareChartInfo.fixed.baseFare.suv + (new Date(dropTime.requested) -
            new Date(pickupTime.requested)) / 60000 * fareChartInfo.fixed.chargePerMinute.suv
            + travelDistance.requested * fareChartInfo.fixed.chargePerDistanceUnit.suv;
          localTripData.sedanFare = fareChartInfo.fixed.baseFare.sedan + (new Date(dropTime.requested) -
            new Date(pickupTime.requested)) / 60000 * fareChartInfo.fixed.chargePerMinute.sedan
            + travelDistance.requested * fareChartInfo.fixed.chargePerDistanceUnit.sedan;
          break;
        case 2:
          localTripData.suvFare = fareChartInfo.fixed.baseFare.suv + (new Date(dropTime.requested) -
            new Date(pickupTime.requested)) / 60000 * fareChartInfo.fixed.chargePerMinute.suv
            + travelDistance.requested * fareChartInfo.fixed.chargePerDistanceUnit.suv;
          localTripData.sedanFare = fareChartInfo.fixed.baseFare.sedan + (new Date(dropTime.requested) -
            new Date(pickupTime.requested)) / 60000 * fareChartInfo.fixed.chargePerMinute.sedan
            + travelDistance.requested * fareChartInfo.fixed.chargePerDistanceUnit.sedan;
          break;
        case 3:
          localTripData.suvFare = fareChartInfo.duration.baseFare.suv + this.props.data.ongoingTripReducer.tripDetails.requestedNumberOfHours * fareChartInfo.duration.hourly.perHourCost.suv;
          localTripData.sedanFare = fareChartInfo.duration.baseFare.sedan + this.props.data.ongoingTripReducer.tripDetails.requestedNumberOfHours * fareChartInfo.duration.hourly.perHourCost.sedan;
          break;
        case 4:
          localTripData.suvFare = fareChartInfo.duration.baseFare.suv + fareChartInfo.duration.halfDay.perHourCost.suv;
          localTripData.sedanFare = fareChartInfo.duration.baseFare.sedan + fareChartInfo.duration.halfDay.perHourCost.sedan;
          break;
        case 5:
          localTripData.suvFare = fareChartInfo.duration.baseFare.suv + fareChartInfo.duration.fullDay.perHourCost.suv;
          localTripData.sedanFare = fareChartInfo.duration.baseFare.sedan + fareChartInfo.duration.fullDay.perHourCost.sedan;
          break;
      }
      localTripData.paymentStatus = this.props.data.ongoingTripReducer.tripDetails.transactionInfo &&
        this.props.data.ongoingTripReducer.tripDetails.transactionInfo.riderTransactionId ? "Payment done" : "Not paid yet";
      localTripData.activityList = this.props.data.ongoingTripReducer.tripDetails.activityLog;
      localTripData.alerts = this.props.data.ongoingTripReducer.tripDetails.alerts;
      let pickUpLocation = this.props.data.ongoingTripReducer.tripDetails.pickupLocation.requested;
      let dropLocation = this.props.data.ongoingTripReducer.tripDetails.dropLocation.requested
      const origin = { lat: pickUpLocation[1], lng: pickUpLocation[0] };
      const destination = { lat: dropLocation[1], lng: dropLocation[0] };


      let alertsAdditional = this.props.data.ongoingTripReducer.tripDetails.alerts
      let tmpAditionalCharge = 0
      // console.log(alertsAdditional,"alertsAdditional")
      if (alertsAdditional.length && alertsAdditional[0].alertType === 5) {
        // console.log(alertsAdditional[0].additionalChargeRequested,"alertsAdditional[0].additionalChargeRequested")
        tmpAditionalCharge = alertsAdditional[0].additionalChargeRequested
        // this.setState({...this.state,additionalChargeVal : alertsAdditional[0].additionalChargeRequested})
      }
      localTripData.notificationContacts = this.props.data.ongoingTripReducer.tripDetails.notificationContacts;
      localTripData.isBusinessTrip = this.props.data.ongoingTripReducer.tripDetails.isBusinessTrip;
      localTripData.additionalComment = this.props.data.ongoingTripReducer.tripDetails.additionalComment;
      localTripData.airline = this.props.data.ongoingTripReducer.tripDetails.airline;
      localTripData.flightNumber = this.props.data.ongoingTripReducer.tripDetails.flightNumber;


      this.setState({
        ...this.state,
        additionalChargeVal: tmpAditionalCharge,
        currentAdditionalChargeVal: tmpAditionalCharge,
        originLocation: origin,
        destinationLocation: destination,
        tripDetails: localTripData,
        detailsFetched: true,
        loading: false
      });
    }



    if (this.props.data.ongoingTripReducer.noShowRejected) {
      this.props.noShowRejectedDone();
      let localTrip = { ...this.state.tripDetails };
      localTrip.alerts = [];
      this.setState({ ...this.state, tripDetails: localTrip, rejectNoShowModalOpen: false });
    }
    if (this.props.data.ongoingTripReducer.noShowApproved) {
      this.props.noShowApprovedDone();
      let localTrip = { ...this.state.tripDetails };
      localTrip.alerts = [];
      this.setState({ ...this.state, tripDetails: localTrip, approveNoShowModalOpen: false });
      this.props.history.push("/web/auth/ongoing-trips");
    }
    if (this.props.data.ongoingTripReducer.additionalChargeApproved) {
      this.props.additionalChargeApprovedDone();
      let localTrip = { ...this.state.tripDetails };
      localTrip.alerts = [];
      this.setState({ ...this.state, tripDetails: localTrip, approveAdditionalChargeModalOpen: false });
    }
    if (this.props.data.ongoingTripReducer.additionalChargeRejected) {
      this.props.additionalChargeRejectedDone();
      let localTrip = { ...this.state.tripDetails };
      localTrip.alerts = [];
      this.setState({ ...this.state, tripDetails: localTrip, rejectAdditionalChargeModalOpen: false });
    }
    if (this.props.data.ongoingTripReducer.endTripDone) {
      this.props.endTripDone();
      this.props.history.push("/web/auth/ongoing-trips");
    }
  }

  componentWillUnmount() {
    this.props.resetDetails();
  }

  handleToggleChange = name => {
    let localMenu = { ...this.state.menu };
    localMenu[name] = !localMenu[name];
    this.setState({ ...this.state, menu: localMenu });
  };

  handleTabChange = (event, tabValue) => {
    this.setState({ tabValue });
  };

  backToPrevPage = () => {
    this.props.history.goBack();
  };

  approveNoShowRequest = () => {
    this.props.approveNoShowRequest(this.state.tripDetails.alerts[0]._id);
  };

  rejectNoShowRequest = () => {
    this.props.rejectNoShowRequest(this.state.tripDetails.alerts[0]._id);
  };

  approveAdditionalChargeRequest = () => {
    let tmpAddCharge = Number(this.state.additionalChargeVal)
    let payload = { additionalCharge: tmpAddCharge }
    this.props.approveAdditionalChargeRequest(this.state.tripDetails.alerts[0]._id, payload);
  };

  rejectAdditionalChargeRequest = () => {
    // console.log(this.state.tripDetails.alerts[0],"this.state.tripDetails.alerts[0]")
    this.props.rejectAdditionalChargeRequest(this.state.tripDetails.alerts[0]._id);
  };

  openApproveNoShowModal = () => {
    this.setState({ approveNoShowModalOpen: true });
  };
  closeApproveNoShowModal = () => {
    this.setState({ approveNoShowModalOpen: false });
  };

  openRejectNoShowModal = () => {
    this.setState({ rejectNoShowModalOpen: true });
  };
  closeRejectNoShowModal = () => {
    this.setState({ rejectNoShowModalOpen: false });
  };
  openApproveAdditionalChargeModal = () => {
    this.setState({ approveAdditionalChargeModalOpen: true });
  };
  closeApproveAdditionalChargeModal = () => {
    let localAddCharge = this.state.currentAdditionalChargeVal;
    this.setState({ approveAdditionalChargeModalOpen: false, additionalChargeVal: localAddCharge });
  };

  openRejectAdditionalChargeModal = () => {
    this.setState({ rejectAdditionalChargeModalOpen: true, });
  };
  closeRejectAdditionalChargeModal = () => {
    let localAddCharge = this.state.currentAdditionalChargeVal;
    this.setState({ rejectAdditionalChargeModalOpen: false, additionalChargeVal: localAddCharge });
  };
  openEndTripModal = () => {
    this.setState({ endTripModalOpen: true });
  };
  closeEndTripModal = () => {
    this.setState({ endTripModalOpen: false });
  };
  endTrip = () => {
    let arrTmpLocation = this.props.data.ongoingTripReducer.tripDetails.path
    if (arrTmpLocation.length) {
      let lastLocation = arrTmpLocation[(arrTmpLocation.length) - 1];
      // console.log('lastLocation ',lastLocation);
      let arrLocNew = [lastLocation[0], lastLocation[1]];
      Geocode.fromLatLng(arrLocNew[1].toString(), arrLocNew[0].toString()).then(
        response => {
          const tmpAdress = response.results[0].formatted_address;
          let payLoad = {
            location: arrLocNew,
            actualDropAddress: tmpAdress
          }
          this.props.endTrip(this.props.match.params.tripId, payLoad);
          // console.log(tmpAdress);
        },
        error => {
          console.error(error);
        }
      );
    } else {
      // let lastLocation =  this.props.data.ongoingTripReducer.tripDetails.bookingLocation
      // let arrLocNew = [lastLocation[1],lastLocation[0]]
      let payLoad = {
        location: this.props.data.ongoingTripReducer.tripDetails.bookingLocation,
        actualDropAddress: this.props.data.ongoingTripReducer.tripDetails.bookingAddress
      }
      this.props.endTrip(this.props.match.params.tripId, payLoad);
    }



  };

  plotZone = () => {
    this.setState({ ...this.state, zonePlotted: true });
  };

  render() {
    const { classes, theme } = this.props;
    const { tabValue } = this.state;

    const MapWithAMarker = withGoogleMap(props => (
      <GoogleMap
        defaultZoom={8}
      >
        <DirectionsRenderer
          directions={this.state.directions}
        />
      </GoogleMap>
    ));

    const selectStyles = {
      MuiOutlinedInput: {
        root: {
          borderColor: "#4A90E2"
        }
      },
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        "& input": {
          font: "inherit"
        }
      })
    };

    return (

      <MuiThemeProvider theme={theme}>
        {!this.state.loading ? (
          <div>
            <Grid container spacing={24}>
              <Grid item xs={12} sm={12}>
                <Grid container>
                  <Grid item xs={12} sm={10} style={{ paddingTop: "10px" }}>
                    <Typography component="p">
                      <span className={classes.pageHeader}>
                        {
                          this.props.data.literalsReducer.OngoingTripDetails.PageHeader
                        }
                      </span>
                      <span className={classes.PageDescription}>

                        {
                          this.props.data.literalsReducer.OngoingTripDetails
                            .PageDescription
                        }
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.headerRightButton}
                      onClick={this.backToPrevPage}
                    >

                      <ArrowBackIosIcon className={classes.leftIcon} /> Back
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: "15px" }}>
              <Grid item xs={12} sm={12}>
                <Paper>
                  <Grid container className={classes.rightPaper}>
                    <Grid item xs={12} sm={9}>
                      <Grid item xs={12} sm={12}>
                        <Grid container>
                          <Grid item xs={12} sm={6}>
                            <Tabs value={tabValue} onChange={this.handleTabChange} className={classes.tabPan}>
                              <Tab label="Chauffeur Details" />
                              <Tab label="Rider Details" />
                            </Tabs>
                            {tabValue === 0 && <TabContainer>
                              <div className={classes.tabDetailsCont}>
                                <div className={classes.profileImageWrap}>
                                  <div className={classes.profileImagePlaceholder}>
                                    {this.state.tripDetails.driverProfilePicture ?

                                      <div style={{
                                        backgroundSize: "cover",
                                        backgroundRepeat: "no-repeat",
                                        width: "100%",
                                        height: "100%",
                                        backgroundImage: `url( ${this.state.tripDetails.driverProfilePicture} )`
                                      }}></div>
                                      : <img
                                        src={DemoUserImage}
                                        alt="no-menu-image"
                                        style={{ width: "100%" }}
                                      />}
                                  </div>
                                  <div style={{ marginLeft: "5px" }}>
                                    <span>{this.state.tripDetails.driverName || "N/A"}</span>

                                    <div
                                      className={classNames(
                                        classes.ratingShow,
                                        classes.description
                                      )}
                                    >
                                      <StarRatings
                                        rating={Number(this.state.tripDetails.driverAvgRating)}
                                        starRatedColor="#ffc000"
                                        numberOfStars={10}
                                        name="rating"
                                        starDimension="15px"
                                        starSpacing="2px"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className={classes.description}>
                                  {this.props.data.literalsReducer.DriverDetails.Email} -
                                  <span>{this.state.tripDetails.driverEmail || "N/A"}</span>
                                </div>
                                <div className={classes.description}>
                                  {this.props.data.literalsReducer.DriverDetails.PhoneNo} -
                                  <span>{this.state.tripDetails.driverPhoneNumber || "N/A"}</span>
                                </div>
                                {/* <div className={classes.description}>
                                {this.props.data.literalsReducer.DriverDetails.DriverId} -
                              <span>{this.state.tripDetails.driverId || "N/A"}</span>
                              </div> */}
                                <div className={classes.description}>
                                  {this.props.data.literalsReducer.DriverDetails.CarType} -
                                  <span>{this.state.tripDetails.driverVehicleType || "N/A"}</span>
                                </div>
                                <div className={classes.description}>
                                  {this.props.data.literalsReducer.DriverDetails.CarMaker} -
                                  <span>{this.state.tripDetails.driverVehicleMake || "N/A"}</span>
                                </div>
                                <div className={classes.description}>
                                  {this.props.data.literalsReducer.DriverDetails.CarModel} -
                                  <span>{this.state.tripDetails.driverVehicleModel || "N/A"}</span>
                                </div>
                                <div className={classes.description}>
                                  {this.props.data.literalsReducer.DriverDetails.CarColor} -
                                  {this.state.tripDetails.driverVehicleColor ?
                                    <span className={classes.colorView} style={{ background: this.state.tripDetails.driverVehicleColor, border: '1px solid #000' }}></span>
                                    : <span>N/A</span>}
                                </div>
                                <div className={classes.description}>
                                    {this.props.data.literalsReducer.DriverDetails.RateChartType} -
                                    <span style={{textTransform:'uppercase'}}>{this.state.tripDetails.driverRateChartType || "N/A"}</span>
                                  </div>
                              </div>
                            </TabContainer>}
                            {tabValue === 1 && <TabContainer>
                              <div className={classes.tabDetailsCont}>
                                <div className={classes.profileImageWrap}>
                                  <div className={classes.profileImagePlaceholder}>
                                    {this.state.tripDetails.riderProfilePicture ?

                                      <div style={{
                                        backgroundSize: "cover",
                                        backgroundRepeat: "no-repeat",
                                        width: "100%",
                                        height: "100%",
                                        backgroundImage: `url( ${this.state.tripDetails.riderProfilePicture} )`
                                      }}></div>

                                      : <img
                                        src={DemoUserImage}
                                        alt="no-menu-image"
                                        style={{ width: "100%" }}
                                      />}
                                  </div>
                                  <div style={{ marginLeft: "5px" }}>
                                    <span>{this.state.tripDetails.riderName || "N/A"}</span>

                                    <div
                                      className={classNames(
                                        classes.ratingShow,
                                        classes.description
                                      )}
                                    >
                                      <StarRatings
                                        rating={Number(this.state.tripDetails.riderAvgRating)}
                                        starRatedColor="#ffc000"
                                        numberOfStars={10}
                                        name="rating"
                                        starDimension="15px"
                                        starSpacing="2px"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className={classes.description}>
                                  {this.props.data.literalsReducer.RiderDetails.Email} -
                                  <span>{this.state.tripDetails.riderEmail || "N/A"}</span>
                                </div>
                                <div className={classes.description}>
                                  {this.props.data.literalsReducer.RiderDetails.PhoneNo} -
                                  <span>{this.state.tripDetails.riderPhoneNumber || "N/A"}</span>
                                </div>
                                {/* <div className={classes.description}>
                                {this.props.data.literalsReducer.RiderDetails.RiderId} -
                              <span>{this.state.tripDetails.riderId || "N/A"}</span>
                              </div> */}
                              </div>
                            </TabContainer>}
                          </Grid>
                          <Grid item xs={12} sm={6} className={classes.breakDownPart}>
                            <h3>Fare Breakdown</h3>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.Estimation} -
                              <Chip label="SUV" className={classes.chip} /><span>&#36;{this.state.tripDetails.suvFare.toFixed(2) || "0"}</span>
                              {this.state.tripDetails.noOfPassenger < 5 ?
                                <span>
                                  <Chip label="SEDAN" className={classes.chip} /><span>&#36;{this.state.tripDetails.sedanFare.toFixed(2) || "0"}</span>
                                </span> : null}
                            </div>
                            <Divider variant="middle" style={{ "margin": "25px 0" }} />
                            {/* <div className={classes.description}>
                            {this.props.data.literalsReducer.OngoingTripDetails.ReqFromDriver} -
                          <span>{this.state.tripDetails.alerts.length ?
                                this.alertTypes[this.state.tripDetails.alerts[0].alertType]
                           : "None"}</span>
                          </div>
                          <Divider variant="middle" style={{ "margin": "25px 0" }} /> */}

                            <div className={classes.description} style={{ height: 200 }}>
                              {/* <MapWithAMarker
                              containerElement={<div style={{ height: `200px` }} />}
                              mapElement={<div style={{ height: `100%` }} />}
                            /> */}

                              <MapComponent
                                plotZone={this.plotZone}
                                zonePlotted={this.state.zonePlotted}
                                origin={this.state.originLocation}
                                destination={this.state.destinationLocation}
                                frmPage={this.state.frmPage}
                                direction={this.state.directions}>
                              </MapComponent>
                            </div>
                            <Divider variant="middle" style={{ "margin": "25px 0" }} />
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.OngoingTripDetails.ReqFromDriver} -
                              <span>
                                {this.state.tripDetails.alerts.length ?
                                  this.alertTypes[this.state.tripDetails.alerts[0].alertType]
                                  : "None"}
                              </span>
                            </div>
                            {this.state.tripDetails.alerts.length && this.state.tripDetails.alerts[0].alertType === 5 ?
                              <div>
                                <div className={classes.description}>
                                  {this.props.data.literalsReducer.OngoingTripDetails.AdditionalChargeReq} -
                                  <span>&#36;{this.state.tripDetails.alerts[0].additionalChargeRequested}</span>
                                </div>

                                <div className={classes.description}>
                                  {this.props.data.literalsReducer.OngoingTripDetails.AdditionalTimeOnTrip} -
                                  {this.state.tripDetails.dropOffTimeRequestedSecond !== "" ?
                                    <Timer
                                      initialTime={this.state.tripDetails.dropOffTimeRequestedSecond}
                                    >
                                      <span>
                                        <Timer.Hours /> :
                                        <Timer.Minutes /> :
                                        <Timer.Seconds />
                                      </span>
                                    </Timer> : null}
                                </div>
                              </div>
                              : null}
                            <div className={classes.breakDownPartAction}>
                              {this.state.tripDetails.alerts.length && this.state.tripDetails.alerts[0].alertType === 6 ?
                                this.state.editableMode ?
                                  <Fragment>
                                    <Button
                                      size="small"
                                      color="secondary"
                                      variant="contained"
                                      onClick={this.openRejectNoShowModal}
                                    >
                                      Reject
                                    </Button>
                                    <Button
                                      size="small"
                                      variant="contained"
                                      color="primary"
                                      className={classes.activeGoButton}
                                      onClick={this.openApproveNoShowModal}
                                    >
                                      Approve
                                    </Button>
                                  </Fragment> : null
                                : null}
                              {this.state.tripDetails.alerts.length && this.state.tripDetails.alerts[0].alertType === 5 ?
                                this.state.editableMode ?
                                  <Fragment>
                                    <Button
                                      size="small"
                                      color="secondary"
                                      variant="contained"
                                      onClick={this.openRejectAdditionalChargeModal}
                                    >
                                      Reject
                                    </Button>
                                    <Button
                                      size="small"
                                      variant="contained"
                                      color="primary"
                                      className={classes.activeGoButton}
                                      onClick={this.openApproveAdditionalChargeModal}
                                    >
                                      Approve
                                    </Button>
                                  </Fragment>
                                  : null : null}

                            </div>
                            <Divider variant="middle" style={{ "margin": "25px 0" }} />
                            <div className={classes.breakDownPartAction}>
                              {window.localStorage.upcAuthData && ((JSON.parse(window.localStorage.upcAuthData).user.roleInfo.roleId &&
                                JSON.parse(window.localStorage.upcAuthData).user.roleInfo.roleId.canEndTrip) ||
                                JSON.parse(window.localStorage.upcAuthData).user.roleInfo.isSuperAdmin) && (this.state.tripDetails.tripStatus === 12) ?
                                <Button
                                  size="small"
                                  color="secondary"
                                  variant="contained"
                                  onClick={this.openEndTripModal}
                                >
                                  End Trip
                                </Button> : null}
                              {this.state.editableMode && (this.state.tripDetails.tripStatus === 5) ?
                                <Link style={{ textDecoration: 'none' }} to={`/web/auth/assign-new-driver/${this.props.match.params.tripId}`} target="_blank">
                                  <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    className={classes.activeGoButton}
                                  >
                                    Assign Alternative Chauffeur
                                  </Button>
                                </Link> : null}
                            </div>
                          </Grid>
                          <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={this.state.endTripModalOpen}
                            onClose={this.closeEndTripModal}
                          >
                            <div style={getModalStyle()} className={classes.modalPaper}>
                              <Grid item xs={12} sm={12} className={classes.descSection}>
                                <Grid container spacing={24}>
                                  <Grid item xs={12} sm={12}>
                                    <h2>End trip</h2>
                                    <div
                                      className={classes.description}
                                      style={{ marginBottom: "20px" }}
                                    >
                                      Are you sure you want to end this trip?
                                    </div>
                                    <div style={{ textAlign: "right" }}>
                                      <Button
                                        size="small"
                                        color="default"
                                        variant="contained"
                                        className={classes.customMargin}
                                        onClick={this.closeEndTripModal}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        style={{ marginLeft: "10px" }}
                                        size="small"
                                        color="primary"
                                        variant="contained"
                                        className={classNames(
                                          classes.customMargin,
                                          classes.activeGoButton
                                        )}
                                        onClick={this.endTrip}
                                      >
                                        Yes
                                      </Button>
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </div>
                          </Modal>
                          <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={this.state.approveNoShowModalOpen}
                            onClose={this.closeApproveNoShowModal}
                          >
                            <div style={getModalStyle()} className={classes.modalPaper}>
                              <Grid item xs={12} sm={12} className={classes.descSection}>
                                <Grid container spacing={24}>
                                  <Grid item xs={12} sm={12}>
                                    <h2>Approve No show request</h2>
                                    <div
                                      className={classes.description}
                                      style={{ marginBottom: "20px" }}
                                    >
                                      Are you sure you want to approve No show request?
                                    </div>
                                    <div style={{ textAlign: "right" }}>
                                      <Button
                                        size="small"
                                        color="default"
                                        variant="contained"
                                        className={classes.customMargin}
                                        onClick={this.closeApproveNoShowModal}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        style={{ marginLeft: "10px" }}
                                        size="small"
                                        color="primary"
                                        variant="contained"
                                        className={classNames(
                                          classes.customMargin,
                                          classes.activeGoButton
                                        )}
                                        onClick={this.approveNoShowRequest}
                                      >
                                        Yes
                                      </Button>
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </div>
                          </Modal>
                          <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={this.state.rejectNoShowModalOpen}
                            onClose={this.closeRejectNoShowModal}
                          >
                            <div style={getModalStyle()} className={classes.modalPaper}>
                              <Grid item xs={12} sm={12} className={classes.descSection}>
                                <Grid container spacing={24}>
                                  <Grid item xs={12} sm={12}>
                                    <h2>Reject No show request</h2>
                                    <div
                                      className={classes.description}
                                      style={{ marginBottom: "20px" }}
                                    >
                                      Are you sure you want to reject No show request?
                                    </div>
                                    <div style={{ textAlign: "right" }}>
                                      <Button
                                        size="small"
                                        color="default"
                                        variant="contained"
                                        className={classes.customMargin}
                                        onClick={this.closeRejectNoShowModal}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        style={{ marginLeft: "10px" }}
                                        size="small"
                                        color="primary"
                                        variant="contained"
                                        className={classNames(
                                          classes.customMargin,
                                          classes.activeGoButton
                                        )}
                                        onClick={this.rejectNoShowRequest}
                                      >
                                        Yes
                                      </Button>
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </div>
                          </Modal>
                          <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={this.state.approveAdditionalChargeModalOpen}
                            onClose={this.closeApproveAdditionalChargeModal}
                          >
                            <div style={getModalStyle()} className={classes.modalPaper}>
                              <Grid item xs={12} sm={12} className={classes.descSection}>
                                <Grid container spacing={24}>
                                  <Grid item xs={12} sm={12}>
                                    <h2>Approve additional charge request</h2>

                                    <div
                                      className={classes.description}

                                    >
                                      <TextField
                                        id="additionalCharge"
                                        label="Additional Charge($)"
                                        className={classes.textField}
                                        name="additionlCharge"
                                        value={this.state.additionalChargeVal}
                                        onChange={this.filterChange}
                                        onInput={e => {
                                          e.target.value = e.target.value.replace(
                                            /[^0-9]/g,
                                            ""
                                          );
                                        }}
                                        inputProps={{
                                          minLength: 1,
                                          maxLength: 4
                                        }}
                                        error={this.state.errors.invalidAdditionalCharge}
                                      />
                                    </div>
                                    <div style={{ textAlign: "right" }}>
                                      <Button
                                        size="small"
                                        color="default"
                                        variant="contained"
                                        className={classes.customMargin}
                                        onClick={this.closeApproveAdditionalChargeModal}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        style={{ marginLeft: "10px" }}
                                        size="small"
                                        color="primary"
                                        variant="contained"
                                        className={classNames(
                                          classes.customMargin,
                                          classes.activeGoButton
                                        )}
                                        onClick={this.approveAdditionalChargeRequest}
                                      >
                                        Yes
                                      </Button>
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </div>
                          </Modal>
                          <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={this.state.rejectAdditionalChargeModalOpen}
                            onClose={this.closeRejectAdditionalChargeModal}
                          >
                            <div style={getModalStyle()} className={classes.modalPaper}>
                              <Grid item xs={12} sm={12} className={classes.descSection}>
                                <Grid container spacing={24}>
                                  <Grid item xs={12} sm={12}>
                                    <h2>Reject additional charge request</h2>
                                    <div
                                      className={classes.description}
                                      style={{ marginBottom: "20px" }}
                                    >
                                      Are you sure you want to reject additional charge request?
                                    </div>
                                    <div style={{ textAlign: "right" }}>
                                      <Button
                                        size="small"
                                        color="default"
                                        variant="contained"
                                        className={classes.customMargin}
                                        onClick={this.closeRejectAdditionalChargeModal}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        style={{ marginLeft: "10px" }}
                                        size="small"
                                        color="primary"
                                        variant="contained"
                                        className={classNames(
                                          classes.customMargin,
                                          classes.activeGoButton
                                        )}
                                        onClick={this.rejectAdditionalChargeRequest}
                                      >
                                        Yes
                                      </Button>
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </div>
                          </Modal>
                        </Grid>
                      </Grid>
                      <Divider variant="middle" style={{ "margin": "25px 0" }} />
                      <Grid item xs={12} sm={12}>
                        <h3>Booking/Trip Details</h3>
                        {this.state.tripDetails.isBusinessTrip ? (
                          <Grid item>
                            <Chip icon={<BusinessCenterIcon fontSize="small" />} label="Business trip" className={classes.chip} />
                          </Grid>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Grid container spacing={24}>
                          <Grid item xs={12} sm={6}>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.BookingId} -
                              <span>{this.state.tripDetails.tripReqBookingId || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.BookingType} -
                              <span>{this.state.tripDetails.tripType || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.NoOfPassenger} -
                              <span>{this.state.tripDetails.noOfPassenger || "0"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.ReqAt} -
                              <span>{this.state.tripDetails.tripReqTime || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.PickupTime} -
                              <span>{this.state.tripDetails.pickupTimeRequested || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.ActualPickupTime} -
                              <span>{this.state.tripDetails.pickupTimeActual || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.ReqPickupLocation} -
                              <span>{this.state.tripDetails.pickupLocationRequested || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.ReqDestination} -
                              <span>{this.state.tripDetails.dropAddressRequested || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.ActualPickupLocation} -
                              <span>{this.state.tripDetails.pickupLocationActual || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.Airline} -
                              <span>{this.state.tripDetails.airline || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.FlightNumber} -
                              <span>{this.state.tripDetails.flightNumber || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.AdditionalComment} -
                              <span>{this.state.tripDetails.additionalComment || "N/A"}</span>
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.ActualDestinationLocation} -
                              <span>{this.state.tripDetails.destinationAddressActual || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.ActualDropOffLocation} -
                              <span>{this.state.tripDetails.dropAddressActual || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.TimeofDropoff} -
                              <span>{this.state.tripDetails.dropOffTime || "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.Status} -
                              <span className={classes.activeStatus}>{this.statusList[this.state.tripDetails.tripStatus]}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.KmsTravelled} -
                              <span>{this.state.tripDetails.travelDistance ? this.state.tripDetails.travelDistance + " km" : "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.WaitTime} -
                              <span>{this.state.tripDetails.waitingTime ? this.state.tripDetails.waitingTime + " hour" : "N/A"}</span>
                            </div>
                            <div className={classes.description}>
                              {this.props.data.literalsReducer.PastTripDetails.TimeOfTravel} -
                              <span>{this.state.tripDetails.travelTime ? this.state.tripDetails.travelTime : "N/A"}</span>
                            </div>
                            {this.state.tripDetails.notificationContacts && this.state.tripDetails.notificationContacts.length ? (
                              <div className={classes.description}>
                                Notification contacts -
                                {this.state.tripDetails.notificationContacts.map(phoneNo => (
                                  <p>
                                    <span>{phoneNo}</span>
                                  </p>
                                ))}
                              </div>
                            ) : null}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={3} className={classes.activityLog}>
                      <h3>Activity Log</h3>
                      <ScrollArea
                        speed={0.8}
                        className={classes.scrollContentSmall}
                        contentClassName="content"
                        horizontal={false}
                      >
                        {this.state.tripDetails.activityList.length ?
                          <List className={classes.root}>
                            {this.state.tripDetails.activityList.map(activity => {
                              return <ListItem alignItems="flex-start" key={activity._id}>
                                <span>
                                  {this.riderActivities.indexOf(activity.actionType) > -1 ?
                                    activity.rider.fullName : this.driverActivities.indexOf(activity.actionType) > -1 ?
                                      (activity.driver && activity.driver.fullName || 'Chauffeur') : this.adminActivities.indexOf(activity.actionType) > -1 ?
                                        activity.admin.fullName : null}
                                </span>{this.activityLogMessageList[activity.actionType]}
                                <p>
                                  <span>{moment(activity.timeStamp).format("MM-DD-YYYY, h:mm a")}</span>
                                </p>
                              </ListItem>
                            })}

                          </List> : <div
                            style={{
                              textAlign: "center",
                              padding: "10px",
                              backgroundColor: "#fff",
                              borderRadius: "10px"
                            }}
                          >No activity found!</div>}
                      </ScrollArea>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </div>) : (
          <div
            style={{
              textAlign: "center",
              padding: "10px",
              backgroundColor: "#fff",
              borderRadius: "10px"
            }}
          >
            {this.props.data.literalsReducer.Common.Loading}
          </div>
        )}
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return { data: state };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchTrip: (id) => dispatch(fetchTrip(id)),
    approveAdditionalChargeRequest: (id, data) => dispatch(approveAdditionalChargeRequest(id, data)),
    rejectAdditionalChargeRequest: (id) => dispatch(rejectAdditionalChargeRequest(id)),
    approveNoShowRequest: (id) => dispatch(approveNoShowRequest(id)),
    rejectNoShowRequest: (id) => dispatch(rejectNoShowRequest(id)),
    endTrip: (id, data) => dispatch(endTrip(id, data)),
    resetDetails: () => dispatch(resetDetails()),
    noShowRejectedDone: () => dispatch(noShowRejectedDone()),
    noShowApprovedDone: () => dispatch(noShowApprovedDone()),
    endTripDone: () => dispatch(endTripDone()),
    additionalChargeApprovedDone: () => dispatch(additionalChargeApprovedDone()),
    additionalChargeRejectedDone: () => dispatch(additionalChargeRejectedDone()),

  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(
    theme => ({
      ...modalStyles(theme),
      ...styles(theme),
      ...contentStyles(theme),
      ...globalstyles(theme)
    }),
    { withTheme: true }
  )(OngoingTripDetails)
);
