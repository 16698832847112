export const setTripFare = (
  tripType,
  localTripData,
  dropTime,
  pickupTime,
  travelDistance,
  distanceTravelled,
  bookingRequestReducer,
  fareChartInfo,
  rateChartType
) => {
  switch (tripType) {
    case 1:
      localTripData[rateChartType].suvFare =
        fareChartInfo[rateChartType].fixed.baseFare.suv +
        ((new Date(dropTime.requested) - new Date(pickupTime.requested)) /
          60000) *
          fareChartInfo[rateChartType].fixed.chargePerMinute.suv +
        travelDistance.requested *
          fareChartInfo[rateChartType].fixed.chargePerDistanceUnit.suv;
      localTripData[rateChartType].sedanFare =
        fareChartInfo[rateChartType].fixed.baseFare.sedan +
        ((new Date(dropTime.requested) - new Date(pickupTime.requested)) /
          60000) *
          fareChartInfo[rateChartType].fixed.chargePerMinute.sedan +
        travelDistance.requested *
          fareChartInfo[rateChartType].fixed.chargePerDistanceUnit.sedan;

      if (distanceTravelled > 10) {
        localTripData[rateChartType].suvFare =
          localTripData[rateChartType].suvFare -
          localTripData[rateChartType].suvFare *
            (fareChartInfo[rateChartType].fixed.discountPercentage / 100);
        localTripData[rateChartType].sedanFare =
          localTripData[rateChartType].sedanFare -
          localTripData[rateChartType].sedanFare *
            (fareChartInfo[rateChartType].fixed.discountPercentage / 100);
      }
      break;
    case 2:
      localTripData[rateChartType].suvFare =
        fareChartInfo[rateChartType].fixed.baseFare.suv +
        ((new Date(dropTime.requested) - new Date(pickupTime.requested)) /
          60000) *
          fareChartInfo[rateChartType].fixed.chargePerMinute.suv +
        travelDistance.requested *
          fareChartInfo[rateChartType].fixed.chargePerDistanceUnit.suv;

      localTripData[rateChartType].sedanFare =
        fareChartInfo[rateChartType].fixed.baseFare.sedan +
        ((new Date(dropTime.requested) - new Date(pickupTime.requested)) /
          60000) *
          fareChartInfo[rateChartType].fixed.chargePerMinute.sedan +
        travelDistance.requested *
          fareChartInfo[rateChartType].fixed.chargePerDistanceUnit.sedan;

      if (distanceTravelled > 10) {
        localTripData[rateChartType].suvFare =
          localTripData[rateChartType].suvFare -
          localTripData[rateChartType].suvFare *
            (fareChartInfo[rateChartType].fixed.discountPercentage / 100);
        localTripData[rateChartType].sedanFare =
          localTripData[rateChartType].sedanFare -
          localTripData[rateChartType].sedanFare *
            (fareChartInfo[rateChartType].fixed.discountPercentage / 100);
      }
      break;
    case 3:
      localTripData[rateChartType].suvFare =
        fareChartInfo[rateChartType].duration.baseFare.suv +
        bookingRequestReducer.tripDetails
          .requestedNumberOfHours *
          fareChartInfo[rateChartType].duration.hourly.perHourCost.suv;
      localTripData[rateChartType].sedanFare =
        fareChartInfo[rateChartType].duration.baseFare.sedan +
        bookingRequestReducer.tripDetails
          .requestedNumberOfHours *
          fareChartInfo[rateChartType].duration.hourly.perHourCost.sedan;
      break;
    case 4:
      localTripData[rateChartType].suvFare =
        fareChartInfo[rateChartType].duration.baseFare.suv +
        fareChartInfo[rateChartType].duration.halfDay.perHourCost.suv;
      localTripData[rateChartType].sedanFare =
        fareChartInfo[rateChartType].duration.baseFare.sedan +
        fareChartInfo[rateChartType].duration.halfDay.perHourCost.sedan;
      break;
    case 5:
      localTripData[rateChartType].suvFare =
        fareChartInfo[rateChartType].duration.baseFare.suv +
        fareChartInfo[rateChartType].duration.fullDay.perHourCost.suv;
      localTripData[rateChartType].sedanFare =
        fareChartInfo[rateChartType].duration.baseFare.sedan +
        fareChartInfo[rateChartType].duration.fullDay.perHourCost.sedan;
      break;
  }

  return localTripData;
};
